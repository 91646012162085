import React from 'react';
import PropTypes from 'prop-types';
import {TimelineLite} from "gsap/all";

import NextActive from "./assets/general/NextDownActive.svg";
import NextInactive from "./assets/general/NextDownInacttive.svg";
import PreviousActive from "./assets/general/PreviousUpActive.svg";
import PreviousInactive from "./assets/general/PreviousUpInactive.svg";
import {step1Duration} from "./constants";

const RedPixel = "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAADUlEQVR42mP8Lxf7HwAGGQJ7q38GiAAAAABJRU5ErkJggg==";
const BlackPixel = "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAADUlEQVQYV2OYOXPmfwAGZQLLkORThAAAAABJRU5ErkJggg=="

const ScrollHeight = 120;

class ScrollBar extends React.PureComponent {
    componentDidMount() {

    }

    componentDidUpdate(prevProps) {
        const ScrollHighlightHeight = ScrollHeight / this.props.pageCount;
        const positionIndexChange = Math.abs(prevProps.positionIndex - this.props.positionIndex)
        // if (prevProps.positionIndex > this.props.positionIndex) {
        //     new TimelineLite().fromTo(['#scroll-highlight-line'], {
        //         top: (ScrollHighlightHeight * prevProps.positionIndex) + '%',
        //     },{
        //         top: (ScrollHighlightHeight * this.props.positionIndex) + '%',
        //         duration: step1Duration /2
        //     })
        // } else if (prevProps.positionIndex < this.props.positionIndex) {
        //     new TimelineLite().from(['#scroll-highlight-line'], {
        //         top: (-ScrollHighlightHeight * positionIndexChange) + '%',
        //         duration: step1Duration /2
        //     })
        // }
    }

    render() {
        const ScrollHighlightHeight = 100 / this.props.pageCount;
        return <div className={"scroll-container"}>
            <img
                alt={"Previous Page"}
                id="scroll-previous-arrow"
                onClick={() => {
                    this.props.onPreviousPage()
                }}
                src={this.props.positionIndex === 0 ? PreviousInactive : PreviousActive}/>

            <div id={"scroll-line-container"}>
                <img id="scroll-guide-line" style={{height: "100%"}} src={BlackPixel} alt={""}/>
                <img id="scroll-highlight-line" style={{
                    height: ScrollHighlightHeight + "%",
                    top: (ScrollHighlightHeight * this.props.positionIndex) + "%"
                }} src={RedPixel} alt={""}/>
            </div>

            <img
                alt={"Next Page"}
                id="scroll-next-arrow"
                onClick={() => {
                    this.props.onNextPage();
                }}
                src={this.props.positionIndex >= this.props.pageCount - 1 ? NextInactive : NextActive}/>
        </div>
    }
}

ScrollBar.propTypes = {
    positionIndex: PropTypes.number.isRequired,
    pageCount: PropTypes.number.isRequired,
    onNextPage: PropTypes.func.isRequired,
    onPreviousPage: PropTypes.func.isRequired
}

export default ScrollBar