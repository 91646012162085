import React from 'react';
import tmpBackground from "../assets/tmp-bg-fs8.png";
import {TweenLite, TweenMax} from "gsap/all";
import {DESKTOP_BREAKPOINT} from "../constants";


export default class BackgroundBase extends React.PureComponent {
    state = { width: window.innerWidth, height: window.innerHeight }

    constructor(props) {
        super(props);
        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowDimensions);
    }

    updateWindowDimensions() {
        this.setState({ width: window.innerWidth, height: window.innerHeight });
    }

    componentDidMount() {
        this.intro()

        window.bgReplay = () => {
            this.intro()
        }

        this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions);
    }

    intro() {
        if (this.state.width >= DESKTOP_BREAKPOINT) {
            this.introDesktop()
        } else {
            this.introMobile()
        }
    }

    swipeUp(targets, delay, duration) {
        const clipFrom =    [0,   100,    100,    100,    100,    100,    0,  100];
        const setWebkitClipPath = () => {
            TweenMax.set(targets, {
                webkitClipPath:'polygon('+clipFrom[0]+'%'+clipFrom[1]+'%,'+clipFrom[2]+'%'+clipFrom[3]+'%,'+clipFrom[4]+'%'+clipFrom[5]+'%,'+clipFrom[6]+'%'+clipFrom[7]+'%)'
            });
        }
        setWebkitClipPath()
        const clipTo =      [0,     0,      100,    0,      100,    100,    0,  100]
        clipTo.onUpdate = setWebkitClipPath
        TweenLite.to(clipFrom, duration, clipTo).delay(delay);
    }

    render() {
        return <div style={{width: "100vw", height: "100vh"}} className="background">
            <img alt="Temporary Background" src={tmpBackground} style={{opacity: 0.5}}/>
        </div>
    }
}

