import * as React from "react";

function SvgHeaderRight(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={10.89}
      height={20.89}
      viewBox="0 0 14.514 27.97"
      {...props}
    >
      <path d="M.536 27.97a.523.523 0 01-.37-.893l13.086-13.086L.152.891a.523.523 0 01.739-.74L14.36 13.62a.523.523 0 010 .74L.906 27.817a.521.521 0 01-.37.153z" />
    </svg>
  );
}

export default SvgHeaderRight;
