import React from 'react';
import {TimelineLite} from "gsap/all";
import {step1Duration} from "../constants";

const BlackPixel = "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAADUlEQVQYV2NIS0v7DwAEmgIymlM5RwAAAABJRU5ErkJggg==";

export default class MobileFooter extends React.PureComponent {
    componentDidMount() {
        new TimelineLite().fromTo(['#footer-line'], {
            width: 0
        }, {
            duration: step1Duration,
            width: "calc(100vw - 200px)"
        })
    }

    render() {
        return <footer>
            <img src={BlackPixel} id={"footer-line"} alt=""/>
            All Rights Reserved 2021
        </footer>
    }
}