import React from 'react';
import PropTypes from 'prop-types';

import {TimelineLite} from 'gsap/all';
import {step1Duration, step2Delay, step2Duration} from "../constants";
import XMark from "../assets/general/Group 22339.svg";
import {Transition} from "react-transition-group";


class PageCard extends React.PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            fullView: false
        }

    }

    toggleFullView(state) {
        this.setState(
            {fullView: state},
            () => this.props.setModalValue(this.renderFullView())
        )
    }

    renderFullView() {
        if (this.state.fullView) {
            return <div className="card-full-container">
                <div className="card-full-bg" onClick={this.toggleFullView.bind(this, false)}/>
                <img alt="Close" src={XMark} className={"close-modal"} onClick={this.toggleFullView.bind(this, false)}/>
                <div className="card-full">
                    <div className="card-full-img-container">
                        <img alt={this.props.title}
                             src={this.props.image[0]}
                             srcSet={encodeURI(this.props.image[0]) + " 315w, " + encodeURI(this.props.image[1]) + " 630w"}
                             sizes="(max-width: 400px) 315w, 630w"
                        />
                    </div>
                    <div className="card-full-body">
                        <h2>{this.props.title}</h2>
                        {this.props.body}
                    </div>
                </div>
            </div>
        }
    }

    render() {
        return <>
            <div className="card" onClick={this.toggleFullView.bind(this, true)}>
                <div className="card-image">
                    <img
                        src={this.props.image[0]}
                        srcSet={encodeURI(this.props.image[0]) + " 315w, " + encodeURI(this.props.image[1]) + " 630w"}
                        sizes="(max-width: 400px) 315w, 630w"
                        alt={this.props.title}/>
                </div>
                <div className="card-body">
                    <h5>{this.props.title}</h5>
                    <p>{this.props.teaser}</p>
                </div>
            </div>
        </>
    }
}

PageCard.propTypes = {
    image: PropTypes.any.isRequired,
    teaser: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    body: PropTypes.element,
    isParentIn: PropTypes.bool.isRequired
}


class PageBase extends React.PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            isEntryAnimationDone: false,
            isExitAnimationDone: false,
            modal: null,
        }
    }

    interval = null;

    componentDidMount() {
        this.interval = setInterval(() => {
            // console.log(this.props.name, this.state)
        }, 1000)
    }

    componentWillUnmount() {
        clearInterval(this.interval)
    }

    onExitAnimation(node) {
        this.setState({modal: null})
        const instanceContainerClass = '.' + this.props.name;
        new TimelineLite().to([
            instanceContainerClass + ' .page-content',
            instanceContainerClass + ' .background',
            instanceContainerClass + ' .page-cards',
            instanceContainerClass + ' .cta-btn',
        ], {
            duration: step1Duration,
            opacity: 0,
            y: this.props.index > this.props.selectedIndex ? "+=30px" : "-=30px"
        });
        console.log("MOVING!!!!", this.props.index, this.props.selectedIndex, this.props.index > this.props.selectedIndex ? "+=30px" : "-=30px")
        new TimelineLite().to([
            instanceContainerClass + ' .overlay-container'
        ], {
            duration: step1Duration,
            opacity: 0,
        });
    }

    onEnterAnimation(node) {
        const instanceContainerClass = '.' + this.props.name;
        new TimelineLite().fromTo([
            // instanceContainerClass + ' .page-content',
            instanceContainerClass + ' .background',
            // instanceContainerClass + ' .page-cards',
            // instanceContainerClass + ' .cta-container'
        ], {
            opacity: 0
        }, {
            duration: step1Duration,
            opacity: 1
        })

        // page header
        new TimelineLite().fromTo([instanceContainerClass + ' .page-content h1'], {
            opacity: 0,
            x: "-20px"
        }, {
            delay: step1Duration + step2Delay,
            duration: step2Duration,
            opacity: 1,
            x: "0px"
        })
        // page content
        new TimelineLite().fromTo([instanceContainerClass + ' .page-content p', instanceContainerClass + ' .page-content .p'], {
            opacity: 0,
            x: "-50px"
        }, {
            delay: step1Duration + step2Delay,
            duration: step2Duration,
            opacity: 1,
            x: "0px"
        })
        // page header icon
        new TimelineLite().fromTo([instanceContainerClass + ' .page-content svg'], {
            opacity: 0,
            y: "50px"
        }, {
            delay: step1Duration + step2Delay,
            duration: step2Duration,
            opacity: 1,
            y: "0px"
        })

        // cards entry animation
        if (this.props.cards && this.props.cards.length) {
            const cardCount = this.props.cards.length;
            for (let i = 0; i < cardCount; i++) {
                new TimelineLite().fromTo([instanceContainerClass + ' .page-cards .card:nth-child(' + (i + 1) + ')'], {
                    opacity: 0,
                    y: (15 * (i + 1)) + "px"
                }, {
                    delay: step1Duration + step2Delay + (step2Duration / 4),
                    duration: step2Duration - (step2Duration / 4),
                    opacity: 1,
                    y: 0
                })
            }
        }

        if (this.props.ctaButton) {
            new TimelineLite().fromTo([instanceContainerClass + ' .cta-btn'], {
                opacity: 0,
                x: "30px"
            }, {
                delay: step1Duration + step2Delay,
                duration: step2Duration,
                opacity: 1,
                x: "0"
            })
        }


        // TweenLite.set();
        new TimelineLite().set(
            [instanceContainerClass + ' .white-gradient'], {
                opacity: 0
            }
        ).fromTo([instanceContainerClass + ' .white-overlay'], {
            opacity: 0,
        }, {
            opacity: 0.1,
            // x: "5vw",
            duration: step1Duration
        }).fromTo([instanceContainerClass + ' .white-gradient'], {
            opacity: 0
        }, {
            opacity: 1,
            x: "49vw",
            duration: step2Duration,
            delay: step2Delay
        })
    }

    renderCards() {
        if (this.props.cards && this.props.cards.length > 0) {
            return <>
                {this.state.modal}
                <div className={"page-cards"}>
                    {this.props.cards.map(cardInfo => <PageCard
                        key={cardInfo.title}
                        image={cardInfo.image}
                        teaser={cardInfo.teaser}
                        title={cardInfo.title}
                        body={cardInfo.body}
                        setModalValue={el => this.setState({modal: el}, () => {
                            this.props.toggleScrollNavigation(!this.state.modal)
                        })}
                        isParentIn={this.props.in}
                    />)}
                </div>
            </>
        }
    }

    render() {
        const pageContentClasses = [
            "page-content",
            this.props.additionalPageContentClass,
        ]
        if (this.props.cards && this.props.cards.length) {
            pageContentClasses.push("with-cards")
        }

        return <Transition
            unmountOnExit={true}
            appear={true}
            timeout={1000}
            in={this.props.in}
            onEnter={(node, isAppearing) => {
                this.onEnterAnimation(node);
            }}
            onExit={(node) => {
                this.onExitAnimation(node)
            }}
            addEndListener={(node, done) => {
                // console.log("addEndListener ", node, done)
            }}
        >
            <div className={this.props.name + " content-container"}>
                <div className={"background"}>
                    {this.props.background}
                </div>
                <div className={"overlay-container"}>
                    <div className={"white-gradient overlay"} style={{left: "calc(-50vw)"}}/>
                    <div className={"white-overlay overlay"}/>
                </div>
                <div className={pageContentClasses.join(' ')}>
                    <div className={"page-content-wrapper"}>
                        {this.props.content}
                    </div>
                </div>
                <div className="cta-container">
                    {this.props.ctaButton}
                </div>

                {this.renderCards()}
            </div>
        </Transition>
    }
}

PageBase.propTypes = {
    content: PropTypes.element.isRequired,
    // cards: PropTypes.arrayOf(PropTypes.element),
    background: PropTypes.element.isRequired,
    ctaButton: PropTypes.element,
    additionalPageContentClass: PropTypes.string,
    in: PropTypes.bool.isRequired,
    name: PropTypes.string.isRequired
}

export default PageBase;