import * as React from "react";

function SvgLoadingLogoD(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={23}
      height={95.309}
      viewBox="0 0 19.802 95.309"
      {...props}
    >
      <path d="M0 47.658V2.041C0 .127.1.024 1.984.019c2.365-.006 4.734-.068 7.094.04a3.479 3.479 0 012.106.87q3.984 3.9 7.779 7.979a3.4 3.4 0 01.807 2.126q.061 36.748 0 73.5a3.181 3.181 0 01-.791 2.005q-3.9 4.07-7.969 7.966a3.349 3.349 0 01-2.041.751c-2.362.091-4.729.041-7.094.033-1.729-.006-1.877-.159-1.877-1.882L0 47.658zm2.306-.087v43.207c0 .338.014.676.006 1.013-.022.844.364 1.224 1.225 1.208 1.52-.027 3.05.1 4.557-.045a3.975 3.975 0 002.212-.926c2.188-2.044 4.3-4.173 6.356-6.353a3.2 3.2 0 00.81-1.993q.062-35.921 0-71.843a3.565 3.565 0 00-.846-2.109c-1.2-1.409-2.727-2.564-3.822-4.044-2.132-2.88-4.849-3.829-8.333-3.42-2.14.251-2.164.055-2.164 2.222l-.001 43.087z" />
    </svg>
  );
}

export default SvgLoadingLogoD;
