
import BackgroundImageRight from "./assets/background/1/Image 31-fs8.png";
import BackgroundImageLeft from "./assets/background/1/Image 32-fs8.png";
import MainImage1 from "./assets/background/1/Image 87-fs8.png";
import MainImageHighlight1 from "./assets/background/1/Image 89-fs8.png"
import Path85 from "./assets/background/1/Path 85.svg";
import Path86 from "./assets/background/1/Path 86.svg";

import BottomImage from "./assets/background/2/Image 44-fs8.png";
import MainOutline2 from "./assets/background/2/Image 79-fs8.png";
import MainImage2 from "./assets/background/2/Image 50-fs8.png";
import MainImageHighlight2 from "./assets/background/2/Image 51-fs8.png";
import Left1Image from "./assets/background/2/Image 45-fs8.png";
import Left2Image from "./assets/background/2/Image 47-fs8.png";
import Right1Image from "./assets/background/2/Image 48-fs8.png";
import Right2Image from "./assets/background/2/Image 46-fs8.png";

import LineLeft from "./assets/background/2/Path 89.svg";
import LineRight from "./assets/background/2/Path 90.svg";

import MainOutline3 from "./assets/background/3/Image 58-fs8.png";
import MainImage3 from "./assets/background/3/Image 57-fs8.png";
import MainImageHighlight3 from "./assets/background/3/Image 59-fs8.png";
import MainImageForegroundShadow from "./assets/background/3/Image 60-fs8.png";

import ForegroundLeft1Image from "./assets/background/3/Image 63-fs8.png";
import ForegroundLeft2Image from "./assets/background/3/Image 62-fs8.png";
import ForegroundRight1Image from "./assets/background/3/Image 64-fs8.png";
import ForegroundRight2Image from "./assets/background/3/Image 65-fs8.png";

import BackgroundLeft1Image4 from "./assets/background/3/Image 53-fs8.png";
import BackgroundLeft2Image from "./assets/background/3/Image 55-fs8.png";
import BackgroundRight1Image4 from "./assets/background/3/Image 54-fs8.png";
import BackgroundRight2Image from "./assets/background/3/Image 56-fs8.png";

import BackgroundLine1 from "./assets/background/3/Path 92.svg";
import BackgroundLine2 from "./assets/background/3/Path 93.svg";
import BackgroundLine3 from "./assets/background/3/Path 94.svg";

import BackgroundCenter from "./assets/background/4/Image 78-fs8.png";
import MainOutline from "./assets/background/4/Image 72-fs8.png";
import MainImage5 from "./assets/background/4/Image 71-fs8.png";
import MainImageHighlight from "./assets/background/4/Image 73-fs8.png";
// import MainImageForegroundShadow from "./assets/background/4/Image 60-fs8.png";
import ForegroundShadow from "./assets/background/4/Image 70-fs8.png";
import Foreground2Image from "./assets/background/4/Image 68-fs8.png";
import Foreground3Image from "./assets/background/4/Image 67-fs8.png";
import ForegroundHighlight from "./assets/background/4/Image 69-fs8.png";

import BackgroundLeft1Image from "./assets/background/4/Image 74-fs8.png";
import BackgroundLeft1Outline from "./assets/background/4/Image 77-fs8.png";
import BackgroundRight1Image from "./assets/background/4/Image 75-fs8.png";
import BackgroundRight1Outline from "./assets/background/4/Image 76-fs8.png";

import BackgroundLineCircle from "./assets/background/4/Ellipse 10.svg";
import BackgroundLineLeft from "./assets/background/4/Path 95.svg";
import BackgroundLineRight from "./assets/background/4/Path 96.svg";

import MainImage from "./assets/background/contactus/Image 80-fs8.png";
import MainOutlineRailingLeft from "./assets/background/contactus/Image 81-fs8.png";
import MainOutlineRailingRight from "./assets/background/contactus/Image 82-fs8.png";

import StaticBg1 from "./assets/background/BG-1-fs8.png";
import StaticBg2 from "./assets/background/BG-2-fs8.png";
import StaticBg3 from "./assets/background/BG-3-fs8.png";
import StaticBg4 from "./assets/background/BG-4-fs8.png";
import StaticBg5 from "./assets/background/BG-5-fs8.png";

import consultationImage from './assets/cards/Consultation & Support.315x268-fs8.png';
import sourceImage from "./assets/cards/Source The Best Suppliers.315x268-fs8.png";
import qualityImage from "./assets/cards/Quality Control.315x268-fs8.png";
import shippingImage from "./assets/cards/Shipping & Logistics.315x268-fs8.png";

import globalSourcingImage from "./assets/cards/Rectangle 11577-4-fs8.png";
import southeastAsia from "./assets/cards/Rectangle 11577-5-fs8.png";

import ourValues from "./assets/cards/Rectangle 11577-6-fs8.png"
import ourStandards from "./assets/cards/Rectangle 11577-7-fs8.png"
import ourFuture from "./assets/cards/Rectangle 11577-8-fs8.png";

export const ImagesToPreload = [
    consultationImage,
    sourceImage,
    qualityImage,
    shippingImage,
    globalSourcingImage,
    southeastAsia,
    ourStandards,
    ourValues,
    ourFuture
]

export const ImagesToPreloadMobile = [
    BackgroundImageRight,
    BackgroundImageLeft,
    MainImage,
    MainImageHighlight,
    Path85,
    Path86,
    BottomImage,
    MainOutline,
    MainImage1,
    MainImageHighlight1,
    Left1Image,
    Left2Image,
    Right1Image,
    Right2Image,
    LineLeft,
    LineRight,
    MainOutline2,
    MainImage2,
    MainImageHighlight2,
    MainImageForegroundShadow,
    ForegroundLeft1Image,
    ForegroundLeft2Image,
    ForegroundRight1Image,
    ForegroundRight2Image,
    BackgroundLeft1Image,
    BackgroundLeft2Image,
    BackgroundRight1Image,
    BackgroundRight2Image,
    BackgroundLine1,
    BackgroundLine2,
    BackgroundLine3,
    BackgroundCenter,
    MainOutline3,
    MainImage3,
    MainImageHighlight3,
    ForegroundShadow,
    Foreground2Image,
    Foreground3Image,
    ForegroundHighlight,
    BackgroundLeft1Image4,
    BackgroundLeft1Outline,
    BackgroundRight1Image4,
    BackgroundRight1Outline,
    BackgroundLineCircle,
    BackgroundLineLeft,
    BackgroundLineRight,
    MainImage5,
    MainOutlineRailingLeft,
    MainOutlineRailingRight
]

export const ImagesToPreloadDesktop = [
    StaticBg1,
    StaticBg2,
    StaticBg3,
    StaticBg4,
    StaticBg5
]

export const step1Duration = 0.600;
export const step2Delay = 0.0;
export const step2Duration = 0.800;


export const touchScrollThresholdDelta = 180;  // previous is 100
export const touchScrollResistance = 0.8; // higher the number, the lighter the scrolling. 1.0 is the default
export const mouseScrollThresholdDelta = 200;

export const scrollMinOpacity = 0.3;

export const MAX_FILE_SIZE = 26214400;

export const DESKTOP_BREAKPOINT = 768;


export const EMAIL_ADDRESS = 'info@decusglobal.com';
