import React from 'react';
import Loading from "./components/Loading";
import PageBase from "./components/PageBase";
import {ContactUs, contactUs, whatWeDoContent, whoWeAreContent, whyChooseUs, whySourceOverseas} from "./content/pages";
import MobileNav from "./components/MobileNav";
import MobileFooter from "./components/MobileFooter";
import Background1 from "./assets/background/Background1";
import Background2 from "./assets/background/Background2";
import {Page2Cards, Page3Cards, Page4Cards} from "./content/cards";
import Background3 from "./assets/background/Background3";
import Background4 from "./assets/background/Background4";
import BackgroundContactUs from "./assets/background/BackgroundContactUs";
import {Link, Route, Router} from "react-router-dom";
import {createHashHistory} from "history";
import {Bounce, TimelineMax, TweenLite} from "gsap/all";
import ScrollBar from "./ScrollBar";
import {
    ImagesToPreloadMobile,
    touchScrollResistance,
    touchScrollThresholdDelta,
    mouseScrollThresholdDelta,
    scrollMinOpacity, ImagesToPreloadDesktop, ImagesToPreload, DESKTOP_BREAKPOINT
} from "./constants";

const history = createHashHistory()


const PageList = [
    '/',
    '/what-we-do',
    '/why-source-overseas',
    '/why-choose-us',
    '/contact-us'
]


const selectorForScrollAnimation = ['.page-content-wrapper', '.page-cards', '.cta-btn']


class App extends React.PureComponent {
    state = {
        isLoaded: false,
        pageCursor: PageList.indexOf(history.location.pathname),
        touchInitialPosition: [0, 0],
        touchLastPosition: [0, 0],
        scrollYPosition: 0,
        lastScrollTime: null,
        scrollNavigationEnabled: true,
        width: window.innerWidth,
        height: window.innerHeight
    }

    scrollCheckInterval = null;

    constructor(props) {
        super(props);
        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    }

    updateWindowDimensions() {
        this.setState({ width: window.innerWidth, height: window.innerHeight });
    }


    loadPageByCursor(cursor) {
        history.push(PageList[cursor])
    }

    keyboardNavigate(e) {
        if (e.keyCode === 38 || e.keyCode === 33) {
            this.previousPage()
        } else if (e.keyCode === 40 || e.keyCode === 34) {
            this.nextPage()
        }
    }

    previousPage(dryRun) {
        const currentCursorIndex = PageList.indexOf(history.location.pathname);
        const condition = currentCursorIndex > 0;
        if (dryRun) {
            return condition;
        }
        if (condition) {
            history.push(PageList[currentCursorIndex - 1])
        }
    }

    nextPage(dryRun) {
        const currentCursorIndex = PageList.indexOf(history.location.pathname);
        const condition = currentCursorIndex < PageList.length - 1
        if (dryRun) {
            return condition;
        }
        if (condition) {
            history.push(PageList[currentCursorIndex + 1])
        }
    }

    resetScrollAnimation() {
        this.setState({lastScrollTime: null, scrollYPosition: 0}, () => {
            new TimelineMax().to(selectorForScrollAnimation, {
                y: 0,
                duration: 0.3,
                opacity: 1,
                // ease: Bounce.easeOut,
                yoyoEase: true
            })
        })
    }

    componentDidMount() {
        this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions);
        window.addEventListener('keydown', this.keyboardNavigate.bind(this))
        this.scrollCheckInterval = setInterval(() => {
            if (this.state.lastScrollTime) {
                const now = (new Date()).getTime()
                const scrollTimeDelta = now - this.state.lastScrollTime;
                if (scrollTimeDelta > 600) {
                    this.resetScrollAnimation()
                }
            }
        }, 500);

        this.historyUnlisten = history.listen((location, action) => {
            this.setState({pageCursor: PageList.indexOf(location.pathname)})
        })

    }

    componentWillUnmount() {
        clearInterval(this.scrollCheckInterval);
        this.historyUnlisten()
        window.removeEventListener('resize', this.updateWindowDimensions);
    }

    handleTouchStart(e) {
        console.log("Touching!", this.state.scrollNavigationEnabled)
        if (this.state.scrollNavigationEnabled) {
            const initialTouch = e.touches[0];
            this.setState({
                touchInitialPosition: [initialTouch.clientX, initialTouch.clientY],
                touchLastPosition: [initialTouch.clientX, initialTouch.clientY]
            })
        }
    }

    handleTouchMove(e) {
        if (this.state.scrollNavigationEnabled) {

            // function is based from https://stackoverflow.com/posts/23230280/revisions
            const touchMove = e.touches[0];
            this.setState({
                touchLastPosition: [touchMove.clientX, touchMove.clientY]
            }, () => {
                const yDelta = this.state.touchInitialPosition[1] - this.state.touchLastPosition[1];
                const xDelta = this.state.touchInitialPosition[0] - this.state.touchLastPosition[0];
                if (Math.abs(yDelta) > Math.abs(xDelta) * 1.5) {
                    const thresholdDeltaDifference = touchScrollThresholdDelta - Math.abs(yDelta)
                    TweenLite.set(selectorForScrollAnimation, {
                        y: -yDelta * touchScrollResistance,
                        opacity: ((thresholdDeltaDifference < 0 ? 0 : thresholdDeltaDifference) + (touchScrollThresholdDelta * scrollMinOpacity))/ touchScrollThresholdDelta
                    })
                } else {
                    TweenLite.set(selectorForScrollAnimation, {
                        y: 0,
                        opacity: 1
                    })
                }
            })
        }

    }

    handleTouchEnd(e) {

        if (this.state.scrollNavigationEnabled) {

            const xDelta = this.state.touchInitialPosition[0] - this.state.touchLastPosition[0];
            const yDelta = this.state.touchInitialPosition[1] - this.state.touchLastPosition[1];

            const maxDelta = Math.max(Math.abs(xDelta), Math.abs(yDelta))
            if (maxDelta < touchScrollThresholdDelta) {
                // max delta is less than threshold
                this.resetScrollAnimation()
                return false;
            }

            if (Math.abs(xDelta) > Math.abs(yDelta)) { // get which is more significant
                if (xDelta > 0) {
                    // left swipe
                } else {
                    // right swipe
                }
            } else {
                if (yDelta > 0) {
                    // swipe up
                    if (this.nextPage(true)) {
                        this.nextPage()
                    } else {
                        this.resetScrollAnimation()
                    }
                } else {
                    // swipe down
                    if (this.previousPage(true)) {
                        this.previousPage()
                    } else {
                        this.resetScrollAnimation()
                    }
                }
            }
        }
    }

    handleWheel(e) {

        if (this.state.scrollNavigationEnabled) {

            const deltaY = e.deltaY;
            this.setState(prevState => ({
                ...prevState,
                scrollYPosition: prevState.scrollYPosition + deltaY,
                lastScrollTime: (new Date()).getTime()
            }), () => {
                setTimeout(() => {
                    const changePage = this.state.scrollYPosition > 0 ? this.nextPage.bind(this) : this.previousPage.bind(this)
                    const canChangePage = changePage(true)
                    if (canChangePage) {
                        if (
                            Math.abs(this.state.scrollYPosition) > mouseScrollThresholdDelta &&  // scroll threshold
                            ((new Date()).getTime() - this.state.lastScrollTime) > 299  // has stopped scrolling
                        ) {
                            this.setState({scrollYPosition: 0, lastScrollTime: null}, () => {
                                changePage()
                            })
                        }
                    } else {
                        this.resetScrollAnimation()
                    }

                }, 300)

                const thresholdDeltaDifference = mouseScrollThresholdDelta - Math.abs(this.state.scrollYPosition)
                TweenLite.set(selectorForScrollAnimation, {
                    y: -this.state.scrollYPosition,
                    opacity: ((thresholdDeltaDifference < 0 ? 0 : thresholdDeltaDifference) + (mouseScrollThresholdDelta * scrollMinOpacity))/ mouseScrollThresholdDelta
                })
            })
        }
    }

    render() {
        if (!this.state.isLoaded) {
            if (!this.state.width) {
                return <></>
            }
            const imagesToPreload = [...ImagesToPreload];
            if (this.state.width >= DESKTOP_BREAKPOINT ) {
                imagesToPreload.push(...ImagesToPreloadDesktop)
            } else {
                imagesToPreload.push(...ImagesToPreloadMobile)
            }
            return <Loading
                imagesToPreload={imagesToPreload}
                onComplete={() => {
                    this.setState({isLoaded: true});
                }}
            />
        }

        return (
            <div className="App"
                 onTouchStart={this.handleTouchStart.bind(this)}
                 onTouchMove={this.handleTouchMove.bind(this)}
                 onTouchEnd={this.handleTouchEnd.bind(this)}
                 onWheel={this.handleWheel.bind(this)}
            >

                <Router history={history}>
                    <Route exact path="/">
                        {({match}) => (<PageBase
                            toggleScrollNavigation={(isEnabled) => {
                                this.setState({scrollNavigationEnabled: isEnabled})
                            }}
                            index={PageList.indexOf('/')}
                            selectedIndex={PageList.indexOf(history.location.pathname)}
                            in={match != null}
                            name={'who-we-are'}
                            // cards={}
                            content={whoWeAreContent}
                            background={<Background1/>}
                            ctaButton={this.state.width >= DESKTOP_BREAKPOINT ? <Link to={"/contact-us"} className="cta-btn" type="button">Source with us</Link> : null}
                        />)}
                    </Route>
                    <Route path="/what-we-do">
                        {({match}) => (<PageBase
                            toggleScrollNavigation={(isEnabled) => {
                                this.setState({scrollNavigationEnabled: isEnabled})
                            }}
                            in={match != null}
                            name={'what-we-do'}
                            index={PageList.indexOf('/what-we-do')}
                            selectedIndex={PageList.indexOf(history.location.pathname)}
                            cards={Page2Cards}
                            content={whatWeDoContent}
                            background={<Background2/>}
                            ctaButton={<Link to={"/contact-us"} className="cta-btn" type="button">Source with us</Link>}
                        />)}
                    </Route>
                    <Route exact path="/why-source-overseas">
                        {({match}) => (<PageBase
                            toggleScrollNavigation={(isEnabled) => {
                                this.setState({scrollNavigationEnabled: isEnabled})
                            }}
                            name={'why-source-overseas'}
                            index={PageList.indexOf('/why-source-overseas')}
                            selectedIndex={PageList.indexOf(history.location.pathname)}
                            in={match != null}
                            cards={Page3Cards}
                            content={whySourceOverseas}
                            background={<Background3/>}
                            ctaButton={<Link to={"/contact-us"} className="cta-btn" type="button">Source with us</Link>}
                        />)}
                    </Route>
                    <Route exact path="/why-choose-us">
                        {({match}) => (<PageBase
                            toggleScrollNavigation={(isEnabled) => {
                                this.setState({scrollNavigationEnabled: isEnabled})
                            }}
                            in={match != null}
                            name={'why-choose-us'}
                            index={PageList.indexOf('/why-choose-us')}
                            selectedIndex={PageList.indexOf(history.location.pathname)}
                            cards={Page4Cards}
                            content={whyChooseUs}
                            background={<Background4/>}
                            ctaButton={<Link to={"/contact-us"} className="cta-btn" type="button">Source with us</Link>}
                        />)}
                    </Route>

                    <Route path="/contact-us">
                        {props => (<PageBase
                            toggleScrollNavigation={(isEnabled) => {
                                this.setState({scrollNavigationEnabled: isEnabled})
                            }}
                            in={props.match != null}
                            name={'contact-us'}

                            index={PageList.indexOf('/contact-us')}
                            selectedIndex={PageList.indexOf(history.location.pathname)}
                            // cards={[]}
                            content={<ContactUs toggleScrollNavigation={(isEnabled) => {
                                this.setState({scrollNavigationEnabled: isEnabled})
                            }}/>}
                            background={<BackgroundContactUs/>}
                            additionalPageContentClass="full-page-content"
                            {...props}
                            // ctaButton={<button className="cta-btn" type="button">Source with us</button> }
                        />)}
                    </Route>
                    <ScrollBar
                        positionIndex={this.state.pageCursor}
                        pageCount={PageList.length}
                        onNextPage={this.nextPage.bind(this)}
                        onPreviousPage={this.previousPage.bind(this)}
                    />
                    <MobileNav toggleScrollNavigation={(isEnabled) => {
                        this.setState({scrollNavigationEnabled: isEnabled})
                    }}/>
                </Router>

                <MobileFooter/>

                <div id="app-modal"></div>
            </div>
        );
    }
}

export default App;
