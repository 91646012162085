import * as React from "react";

function SvgLoadingLogoS2(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={23}
      height={95.27}
      viewBox="0 0 19.778 95.27"
      {...props}
    >
      <path
        fill="#FF751F"
        d="M17.498 61.3c0-9.842-.014-19.684.018-29.526a3.638 3.638 0 00-1.158-2.8C11.234 23.89 6.119 18.797 1.072 13.638a3.968 3.968 0 01-1-2.425C-.045 8.092.018 4.964.027 1.838.027.116.144.006 1.796.005Q9.842 0 17.889.005c1.657 0 1.881.233 1.89 1.918 0 .8-.008 1.6-.015 2.407-.006.786-.425 1.139-1.189 1.159-.789.021-.982-.444-1.04-1.124-.173-2.062-.187-2.065-2.217-2.067H3.913c-1.478 0-1.676.2-1.689 1.691-.017 2.112-.1 4.23.043 6.333a3.922 3.922 0 001.008 2.309q7.492 7.64 15.121 15.147a4.339 4.339 0 011.375 3.361q-.046 30.92-.021 61.84c0 2.16-.129 2.29-2.3 2.29H1.99c-1.834 0-1.96-.116-1.96-1.919q0-26.928.006-53.856c0-.769-.183-1.8 1.015-1.808s1.176.976 1.176 1.845v41.564c0 3.379-.01 6.758 0 10.137 0 1.56.2 1.746 1.758 1.748q5.893.009 11.785 0c1.545 0 1.733-.2 1.734-1.775q-.001-14.952-.006-29.905z"
      />
    </svg>
  );
}

export default SvgLoadingLogoS2;
