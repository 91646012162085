import React from 'react';
import {LOADING_LETTER_INDEX, LOADING_LETTERS} from "../content/loadingAssets";
import {TimelineLite} from 'gsap/all';
import SvgLogoPartialLeft from "../assets/loading/LogoPartialLeft";
import SvgLogoPartialRight from "../assets/loading/LogoPartialRight";


const introAnimationDuration = 1;


const getNameLogo = function(character, counter) {
    const charIndex = LOADING_LETTER_INDEX[character];
    let charSubIndex = counter - charIndex;
    if (charSubIndex <= 0) {
        return LOADING_LETTERS[character][0];
    } else if (charSubIndex === 1) {
        return LOADING_LETTERS[character][1]
    } else if (charSubIndex < 5) {
        return LOADING_LETTERS[character][2];
    } else if (charSubIndex === 6) {
        return LOADING_LETTERS[character][3]
    }
    return LOADING_LETTERS[character][0];
}

const cacheImages = async (srcArray) => {
    const promises = await srcArray.map(src => {
        return new Promise(function(resolve, reject) {
            const img = new Image();
            img.src = src;
            img.onload = () => { console.log("Loaded " + src); resolve(); }
            img.onerror = e => { console.error("Cannot load " + src); reject(e); }
        })
    })

    return Promise.all(promises);
}


export default class Loading extends React.PureComponent {
    state = {
        shouldAnimate: false,
        isStopped: false,
        isPreloadingComplete: false,
        nameIndex: 0,
    }

    constructor(props) {
        super(props);

        this.name = null;
        this.left = null;
        this.right = null;
    }

    componentDidMount() {
        this.introAnimation()
    }
    componentWillUnmount() {
        clearInterval(this.animator)
    }

    introAnimation() {
        new TimelineLite().fromTo([this.name], {
            y: "2em",
            opacity: 0,
        }, {
            y: "0",
            opacity: 1,
            duration: introAnimationDuration,
            onComplete: () => this.onInitialAnimationDone()
        })
        new TimelineLite().fromTo(['.logo-left'], {
            x: '100px',
            opacity: 0,
        }, {
            x: 0,
            opacity: 1,
            duration: introAnimationDuration
        })

        new TimelineLite().fromTo(['.logo-right'], {
            x: '-100px',
            opacity: 0,
        }, {
            x: '0',
            opacity: 1,
            duration: introAnimationDuration
        })
    }

    outroAnimation() {
        new TimelineLite().to([this.name], {
            y: "-80px",
            opacity: 0,
            duration: introAnimationDuration,
            onComplete: () => {
                window.setTimeout(() => {
                    console.log(new Date(), "Running onComplete")
                    this.props.onComplete()
                }, (introAnimationDuration / 4) * 1000)
            }
        })
        new TimelineLite().to(['.logo-left'], {
            x: '-100px',
            opacity: 0,
            duration: introAnimationDuration
        })

        new TimelineLite().to(['.logo-right'], {
            x: '100px',
            opacity: 0,
            duration: introAnimationDuration
        })
    }


    onInitialAnimationDone() {
        this.setState({shouldAnimate: true}, () => {
            console.log("onInitialAnimationDone")
            this.preloadImages()
            this.initNameAnimation()
        })
    }

    initNameAnimation() {
        const _this = this;
        this.animator = window.setInterval(function() {
            _this.setState(prevState => {
                const newIndex = prevState.nameIndex >= 13 ? 0 : prevState.nameIndex + 1;
                if (!prevState.shouldAnimate && prevState.nameIndex === 0) {
                    if (prevState.isPreloadingComplete) {
                        clearInterval(_this.animator);
                        console.log("Running outroAnimation")
                        _this.outroAnimation()
                    }
                    return {...prevState}
                }
                return {
                    ...prevState,
                    nameIndex: newIndex
                }
            })
        }, 170)
    }

    preloadImages() {
        // TODO: handle image loading errors
        cacheImages(this.props.imagesToPreload).then(() => {
            this.setState({
                shouldAnimate: false,
                isPreloadingComplete: true
            })
        })
    }

    render() {
        return <>
            <div className="loading-logo-container">
                <SvgLogoPartialLeft
                    className="loading-logo logo-left"/>
                <SvgLogoPartialRight
                    className="loading-logo logo-right"/>
            </div>

            <div
                ref={div => this.name=div}
                className="loading-name-container"
                onClick={() => this.setState({shouldAnimate: !this.state.shouldAnimate})}
            >
                {getNameLogo('d', this.state.nameIndex)}
                {getNameLogo('e', this.state.nameIndex)}
                {getNameLogo('c', this.state.nameIndex)}
                {getNameLogo('u', this.state.nameIndex)}
                {getNameLogo('s', this.state.nameIndex)}
            </div>
        </>
    }
}

