import * as React from "react";

function SvgRune1(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 15.53 31.04"
      {...props}
    >
      <g data-name="Layer 2">
        <g data-name="Layer 1">
          <g
            data-name="Group 22354"
            fill="none"
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <path
              data-name="Path 13642-2"
              d="M15 6.54L8.24.5v30L1.5 23.76"
              style={{
                isolation: "isolate",
              }}
              stroke="#ff1e5d"
              opacity={0.7}
            />
            <path
              data-name="Path 13643-2"
              d="M14 6.57L7.24.54v30L.5 23.8"
              stroke="#8f1fff"
            />
          </g>
        </g>
      </g>
    </svg>
  );
}

export default SvgRune1;
