import consultationImage from '../assets/cards/Consultation & Support.315x268-fs8.png';
import sourceImage from "../assets/cards/Source The Best Suppliers.315x268-fs8.png";
import qualityImage from "../assets/cards/Quality Control.315x268-fs8.png";
import shippingImage from "../assets/cards/Shipping & Logistics.315x268-fs8.png";
import consultationImage2 from '../assets/cards/Consultation & Support.630x536-fs8.png';
import sourceImage2 from "../assets/cards/Source The Best Suppliers.630x536-fs8.png";
import qualityImage2 from "../assets/cards/Quality Control.630x536-fs8.png";
import shippingImage2 from "../assets/cards/Shipping & Logistics.630x536-fs8.png";

import globalSourcingImage from "../assets/cards/Rectangle 11577-4-fs8.png";
import southeastAsia from "../assets/cards/Rectangle 11577-5-fs8.png";

import ourValues from "../assets/cards/Rectangle 11577-6-fs8.png"
import ourStandards from "../assets/cards/Rectangle 11577-7-fs8.png"
import ourFuture from "../assets/cards/Rectangle 11577-8-fs8.png";

import globalSourcingImage2 from "../assets/cards/Rectangle 11577-4@2x-fs8.png";
import southeastAsia2 from "../assets/cards/Rectangle 11577-5@2x-fs8.png";

import ourValues2 from "../assets/cards/Rectangle 11577-6@2x-fs8.png"
import ourStandards2 from "../assets/cards/Rectangle 11577-7@2x-fs8.png"
import ourFuture2 from "../assets/cards/Rectangle 11577-8@2x-fs8.png";

export const Page2Cards = [
    {
        image: [consultationImage, consultationImage2],
        title: "Consultation & Support",
        teaser: "World-class consultation and expert support for your business",
        body: <>
            <p>
                We identify the kind of goods and services you are looking for to get a clear picture and match the best
                supplier for you.
            </p>
            <p>We will also be calculating the local cost of materials to compare.</p>
            <ul>
                <li>Project Briefing</li>
                <li>Design Review</li>
                <li>Billing of Materials & Quantities</li>
                <li>Product Chart</li>
                <li>Calculating Local Cost of Materials</li>
            </ul>
        </>
    },
    {
        image: [sourceImage, sourceImage2],
        title: "Source the Best Suppliers",
        teaser: "Matching the right and suitable suppliers from our list or finding specific ones",
        body: <>
            <p>
                Matching the right and suitable suppliers from our supplier list or looking for specific ones and
                Getting quotations from suppliers, which will undergo a series of evaluations.
            </p>
            <ul>
                <li>Contact Suppliers</li>
                <li>Quotation Review</li>
                <li>Factor | Store | Office Visit</li>
                <li>Negotiation</li>
                <li>Contract Signing</li>
            </ul>
        </>
    },
    {
        image: [qualityImage, qualityImage2],
        title: "Quality Control",
        teaser: "We secure inspections for materials, products and manufacturers",
        body: <>
            <p>
                Quality Controllers visit the factory regularly to make sure we meet our buyer’s requirements. Our
                quality control personnel are stationed at all manufacturing sites to ensure that all products meet the
                buyer’s needs.
            </p>
            <ul>
                <li>Safety & Compliance</li>
                <li>Pre-production Inspection</li>
                <li>Samples & Prototypes</li>
                <li>Production Quality Control</li>
                <li>Production Timeline & Delivery</li>
                <li>Balance Payment</li>
                <li>Consolidation (Packing List)</li>
                <li>Post production Inspection</li>
            </ul>
        </>
    },
    {
        image: [shippingImage, shippingImage2],
        title: "Shipping & Logistics",
        teaser: "Secure shipping and round-the-clock logistics management",
        body: <>
            <p>
                Only approved goods are packed; this is to ensure that the buyer gets quality merchandise. We also see
                to it that all packing instructions of the buyer are respected.
            </p>
            <p>
                All shipping documents are reviewed and
                verified as per the buyer’s instruction. The reputable forwarder handles all cargo to obtain correct
                information regarding ETD and ETA of vessels because time delivery is essential for us as it is to the
                buyer.
            </p>
            <ul>
                <li>Shipping via a Logistics Company</li>
                <li>Local Customs and Clearance</li>
                <li>Delivery</li>
            </ul>
        </>
    }
];

export const Page3Cards = [
    {
        image: [globalSourcingImage, globalSourcingImage2],
        title: "Global Sourcing",
        teaser: "Our global sourcing services and opportunities provides more competitive options.",
        body: <>
            <p>
                Decus offers our global sourcing services and opportunities for all buyers, contractors, developers, and
                the like to provide a variety sourcing solution.
            </p>
            <p>
                Decus’ goal is to give you a more comprehensive array of options with a strategic and reliable partner
                to help you execute your visions and plans.
            </p>
            <p>
                Our long term goal is to provide the best sourcing solution for your need. We want to help you improve
                your business by identifying the most cost-effective way to address your needs.
            </p>
        </>
    },

    {
        image: [southeastAsia, southeastAsia2],
        title: "Southeast Asia",
        teaser: "China's manufacturing industry continuously increase in the past 30 years. They are now the world's largest manufacturing economy.",
        body: <>
            <p>
                We have witnessed China’s manufacturing industry continuously increase in the past 30 years. They now
                have the world’s largest manufacturing economy. China’s products can be seen anywhere, globally, which
                is why they are now known as “The World’s Factory.”
            </p>

            <p>
                This is why China can offer more opportunities and broader
                options for businesses of all kinds. Not only that but their product’s quality is outstanding due to
                their very competitive market, and now, their manufacturing capabilities have evolved from low-cost
                goods to more advanced products.
            </p>
        </>
    }
]


export const Page4Cards = [
    {
        image: [ourValues, ourValues2],
        title: "Our Values",
        teaser: "We aim to be the solution that enables progressive growth for your business.",
        body: <>
            <p>
                If you’re looking to upgrade, start, or expand your business, we want to help you start executing your
                plans and visions as soon as possible. We have access to local designers, architects, contractors,
                business owners who can help us with your needs.
            </p>
            <p>
                We aim to be the solution that enables progressive growth for your business
            </p>
        </>
    },
    {
        image: [ourStandards, ourStandards2],
        title: "Our Standards",
        teaser: "Unrelenting personalized customer service with a global quality sourcing solution.",
        body: <>
            <p>
                Highlights the importance of strategic sourcing that allows your business to thrive now and further into
                the future by connecting you to reliable and trustworthy suppliers and distributors that matches our
                obsession to quality and innovation.
            </p>
        </>
    },
    {
        image: [ourFuture, ourFuture2],
        title: "Our Future",
        teaser: "We are looking forward to a long-lasting and honest partnership",
        body: <>
            <p>
                We are looking forward to a long-lasting and honest partnership, and we are here to make sure doing
                business is convenient and prosperous for all of us.
            </p>
            <p>
                Having partners in Israel, Russia, Germany, and the USA, expands our initial market exponentially, being
                able to work with various industries such as Commercial contractors, Developers, Residential projects
                from ground zero to furnishing, Designers and Architects, Suppliers, Business startups, Government
                orders, and innovators that places our future to a bright and productive one.
            </p>
        </>
    }
]