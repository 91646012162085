import * as React from "react";

function SvgRune4(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 12.627 32"
      {...props}
    >
      <defs>
        <style>
          {
            ".rune4_svg__b{fill:none;stroke-linecap:round;stroke-linejoin:round;stroke:#ff751f}"
          }
        </style>
      </defs>
      <path
        d="M1.704 30.5V.5l10.219 8.6"
        stroke="#ffe51c"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        className="rune4_svg__b"
        d="M.704 31.5v-30l10.219 8.6M.704 12.088l10.219 8.6"
      />
    </svg>
  );
}

export default SvgRune4;
