import React from 'react';
import {HeaderLeft, HeaderName, HeaderRight} from "../assets/logo";
import {TimelineLite} from "gsap/all";
import {Rune1, Rune2, Rune3} from "../assets/runes";
import facebookLogo from "../assets/general/Path 35.svg";
import twitterLogo from "../assets/general/Path 41.svg";
import pinterestLogo from "../assets/general/Path 37.svg";
import instagramLogo from "../assets/general/Group 53.svg";
import linkedInLogo from "../assets/general/Group 56.svg";
import {Link} from "react-router-dom";
import {step1Duration, step2Delay, step2Duration} from "../constants";


class MobileMenu extends React.PureComponent {
    componentDidMount() {
        this.intro()
    }

    outro() {
        new TimelineLite().fromTo(['#mobile-menu'], {
            opacity: 1
        }, {
            opacity: 0,
            duration: step1Duration / 2,
            onComplete: () => {
                this.props.onNavigate()
            }
        })
    }

    intro() {

        new TimelineLite().fromTo(['#mobile-menu'], {
            opacity: 0,
        }, {
            duration: step1Duration / 2,
            opacity: 1,
        })

        const iconCount = 5;
        for (let i = 0; i < iconCount; i++) {
            new TimelineLite().fromTo(['.icon-group .menu-social-icon:nth-child('+(i+1)+')'], {
                opacity: 0,
                y: (20 * (i+1)) + "px"
            }, {
                duration: step1Duration,
                opacity: 1,
                y: 0
            })
        }

        new TimelineLite().fromTo(['.menu-header'], {
            opacity: 0,
            y: "20px"
        }, {
            duration: step1Duration,
            opacity: 1,
            y: 0
        })


        new TimelineLite().fromTo(['.menu-item'], {
            opacity: 0,
        }, {
            duration: step1Duration,
            opacity: 1,
        })


        new TimelineLite().fromTo(['.menu-item .our-company-menu.menu-label h6'], {
            y: "-20px",
        }, {
            duration: step1Duration,
            y: "0px",
        })
        new TimelineLite().fromTo(['.menu-item .what-we-do-menu.menu-label h6'], {
            y: "-40px",
        }, {
            duration: step1Duration,
            y: "0px",
        })

        new TimelineLite().fromTo(['.menu-item .contact-us-menu.menu-label h6'], {
            y: "60px",
        }, {
            duration: step1Duration,
            y: "0px",
        })
        new TimelineLite().fromTo(['.menu-item .contact-us-menu.menu-logo svg'], {
            y: "80px",
        }, {
            duration: step1Duration,
            y: "0px",
        })
    }
    render() {
        return <div id="mobile-menu">
            <div className="menu-header">
                <div className="menu-logo">
                    <HeaderLeft className="menu-logo logo-left"/>
                    <HeaderRight className="menu-logo logo-right"/>
                </div>
                <HeaderName/>
            </div>
            <div className="menu-item">
                <Link to="/" className="menu-logo our-company-menu" onClick={this.outro.bind(this)}>
                    <Rune1 height={"30px"}/>
                </Link>
                <Link to="/" className="our-company-menu menu-label" onClick={this.outro.bind(this)}>
                    <h6>Our Company</h6>
                </Link>
            </div>
            <div className="menu-item">
                <Link to='/what-we-do' className="menu-logo what-we-do-menu" onClick={this.outro.bind(this)}>
                    <Rune2 height={"30px"}/>
                </Link>
                <Link to='/what-we-do' className="what-we-do-menu menu-label" onClick={this.outro.bind(this)}>
                    <h6>Our Services</h6>
                </Link>
            </div>
            <div className="menu-item">
                <Link className="menu-logo contact-us-menu" to='/contact-us' onClick={this.outro.bind(this)}>
                    <Rune3 height={"30px"}/>
                </Link>
                <Link to='/contact-us' className="contact-us-menu menu-label" onClick={this.outro.bind(this)}>
                    <h6>Contact Us</h6>
                </Link>
            </div>

            <div className="menu-footer">
                <div className="icon-group">
                    <img alt="Facebook" src={facebookLogo} className="menu-social-icon"/>
                    <img alt="Twitter" src={twitterLogo} className="menu-social-icon"/>
                    <img alt="Pinterest" src={pinterestLogo} className="menu-social-icon"/>
                    <img alt="Instagram" src={instagramLogo} className="menu-social-icon"/>
                    <img alt="LinkedIn" src={linkedInLogo} className="menu-social-icon"/>
                </div>

                Decus Inc. © 2021
            </div>
        </div>
    }
}

class HeaderLogo extends React.PureComponent {
    componentDidMount() {
        new TimelineLite().fromTo(['.header-logo.logo-left'], {
            x: "30px",
            opacity: 0,
        }, {
            duration: 1.0,
            x: "0",
            opacity: 1,
        })
        new TimelineLite().fromTo(['.header-logo.logo-right'], {
            x: "-30px",
            opacity: 0,
        }, {
            duration: 1.0,
            x: "0",
            opacity: 1,
        })
    }

    render() {
        return <div onClick={this.props.onClick}>
            <HeaderLeft className="header-logo logo-left" height="21"/>
            <HeaderRight className="header-logo logo-right"/>
            <HeaderName/>
        </div>
    }
}


export default class MobileNav extends React.PureComponent {

    state = {
        showMenu: false
    }

    render() {
        return <nav>
            {this.state.showMenu && <MobileMenu onNavigate={() => this.setState({showMenu: false}, () => {
                this.props.toggleScrollNavigation(!this.state.showMenu)
            })}/>}
            <HeaderLogo onClick={() => this.setState({showMenu: true}, () => {
                this.props.toggleScrollNavigation(!this.state.showMenu)
            })}/>
        </nav>
    }
}