import * as React from "react";

function SvgLoadingLogoD1(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={23}
      height={94.099}
      viewBox="0 0 22 94.099"
      {...props}
    >
      <path
        d="M1 93V1l20 92.1V1z"
        fill="none"
        stroke="#ff1e5d"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
      />
    </svg>
  );
}

export default SvgLoadingLogoD1;
