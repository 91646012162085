import * as React from "react";

function SvgRune5(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 15.941 31.25"
      {...props}
    >
      <defs>
        <style>
          {
            ".rune5_svg__a,.rune5_svg__b{fill:none;stroke-linecap:round;stroke-linejoin:round}.rune5_svg__a{stroke:#00e2f1}.rune5_svg__b{stroke:#ff1e5d}"
          }
        </style>
      </defs>
      <path
        className="rune5_svg__a"
        d="M1.705 30.751v-30M15.235 3.784L1.704 15.81M8.522.751L1.704 6.806"
      />
      <g>
        <path
          className="rune5_svg__b"
          d="M.705 30.706v-30M14.235 3.739L.704 15.765M7.522.706L.704 6.761"
        />
      </g>
    </svg>
  );
}

export default SvgRune5;
