import {Rune1, Rune2, Rune4, Rune5} from "../assets/runes";
import React from "react";
import ReactDOM from 'react-dom';
// import FormButtonBackground from "../assets/"
import UploadButton from "../assets/general/Group 22348.svg"
import XButton from "../assets/general/Group 22339.svg";

import mailIcon from "../assets/general/Group 22338.svg";
import phoneIcon from "../assets/general/Path 13646.svg";
import addressIcon from "../assets/general/Path 13647.svg";
import {EMAIL_ADDRESS, MAX_FILE_SIZE, step1Duration, step2Delay, step2Duration} from "../constants";
import {TimelineLite, TweenMax} from "gsap/all";
import {
    getPresignedPostData,
    getReadableFileSizeString,
    sendContactUs,
    uploadFileToS3,
    validateEmail
} from "../utility";


const RedPixel = "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAADUlEQVR42mP8Lxf7HwAGGQJ7q38GiAAAAABJRU5ErkJggg==";
const GrayPixel = "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNk+A8AAQUBAScY42YAAAAASUVORK5CYII="


export const whoWeAreContent = <div>
    <h1>
        Who We Are <Rune1/>
    </h1>
    <p>
        Decus Inc. is a global sourcing and trading company based in Metro Manila.
        You can find our headquarters at the heart of its commercial hub, Bonifacio Global City.
    </p>
    <p>
        Our company highlights the importance of strategic sourcing that allows your business to
        thrive now and further into the future by connecting you to reliable and trustworthy suppliers and distributors.
    </p>
    <p>
        We cater to industries and businesses of all sizes who need supplies and goods from China or anywhere globally.
        Our uniqueness lies in our personalized services for our clients.
    </p>
</div>

export const whatWeDoContent = <div>
    <h1>
        What We do <Rune2/>
    </h1>
    <p>
        Here at Decus Inc.,
        we make sure we save you time,
        money and reduce sourcing risks.
    </p>
    <p>
        Our expertise lay in construction from the ground up to furnishing. We work closely with developers, architects,
        interior designers, contractors, and single clients.
    </p>
</div>


export const whySourceOverseas = <div>
    <h1>
        Why Source Overseas <Rune4/>
    </h1>

    <p>
        For the best value for your money, your domestic resource might not be the right place to be looking at for your
        sourcing needs.
    </p>
    <p>
        Sourcing overseas gives you more options and will allow you to save costs.
    </p>
</div>

export const whyChooseUs = <div>
    <h1>
        Why Choose Us <Rune5/>
    </h1>
    <p>
        Investing in people is what ultimately builds a business, the trust that we’ve accumulated Is the currency that
        pushes us to pursue a customer first experience.
    </p>
    <p>
        Providing value and helping the customer complete their goal, that’s why we are in this industry.
    </p>
</div>


export class ContactUs extends React.PureComponent {
    recaptchaSiteKey = '6LcqihYaAAAAADWf06c80XmJuatA96pQ7_STCL5-';
    state = {
        fileUpload: null,
        from: '',
        subject: '',
        content: '',
        hasInitializedUpload: false,
        uploadProgress: 0,
        isSent: false,
        isSending: false,
        hasSendingError: false,
        isCancelled: false
    }

    constructor(props) {
        super(props);
        this.fileInput = React.createRef();
    }

    async generateRecaptchaToken(action) {
        const _this = this;
        return new Promise((resolve) => {
            if (this.state.subject === 'testinvalidtoken') {
                return resolve("INVALIDTOKEN")
            }
            if (window.grecaptcha) {
                window.grecaptcha.execute(_this.recaptchaSiteKey, {action: action}).then(function (token) {
                    resolve(token)
                }).catch(() => {
                    _this.setState({hasSendingError: true});
                });
            } else {
                resolve("NOTOKEN")
            }
        })
    }

    sendContactUsForm() {
        const _this = this;
        this.setState({isSending: true, isCancelled: false}, () => {
            _this.props.toggleScrollNavigation(false);
            _this.uploadFile().then(responses => {
                if (this.state.isCancelled) {
                    return false
                }

                this.generateRecaptchaToken('sendContactUs').then(token => {
                    sendContactUs(
                        _this.state.from,
                        _this.state.subject,
                        _this.state.content,
                        responses.startUploadResponse && responses.startUploadResponse.tmp_path,
                        _this.state.fileUpload && _this.state.fileUpload.name,
                        token
                    ).then(response => {
                        _this.setState({isSent: true})
                    }).catch(() => {
                        this.setState({hasSendingError: true});
                    })
                })
            })
        })
    }

    uploadFile() {
        // here preSignedPostData is the data returned from the function above
        const _this = this;
        const axiosConfig = {
            onUploadProgress: function (progressEvent) {
                const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
                _this.setState({uploadProgress: percentCompleted})
            }
        }
        return new Promise(resolve => {
            this.generateRecaptchaToken('startUpload').then(token => {
                // Add your logic to submit to your backend server here.
                const file = _this.state.fileUpload;
                if (!file) {
                    resolve({endUploadResponse: null, startUploadResponse: null});
                    return;
                }

                _this.setState({hasInitializedUpload: true}, () => {
                    getPresignedPostData(file, token).then(signedDataResponse => {
                        const signedData = signedDataResponse.data.response;
                        uploadFileToS3(signedData, file, axiosConfig).then(uploadResponse => {
                            resolve({
                                endUploadResponse: uploadResponse.data,
                                startUploadResponse: signedDataResponse.data
                            })
                        }).catch(error => {
                            this.setState({hasSendingError: true});
                            console.error("Something went wrong during upload", error)
                        })

                    }).catch(error => {
                        this.setState({hasSendingError: true});
                        console.error("Something went wrong while starting upload", error)
                    })
                });
            })
        })
    }

    componentDidMount() {
        window.grecaptcha && window.grecaptcha.ready(function () {
        });
        new TimelineLite().from(['#submit'], {
            duration: step2Duration,
            delay: step1Duration + step2Delay,
            x: "20px",
        })
        new TimelineLite().from(['#upload'], {
            duration: step2Duration,
            delay: step1Duration + step2Delay,
            x: "40px",
        })

        new TimelineLite().from(['.slide-up-3'], {
            duration: step2Duration,
            delay: step1Duration + step2Delay,
            y: "90px",
        })
        new TimelineLite().from(['.slide-up-2'], {
            duration: step2Duration,
            delay: step1Duration + step2Delay,
            y: "60px",
        })
        new TimelineLite().from(['.slide-up-1'], {
            duration: step2Duration,
            delay: step1Duration + step2Delay,
            y: "30px",
        })

        new TimelineLite().from(['.fade-in'], {
            duration: step2Duration,
            delay: step1Duration + step2Delay,
            opacity: 0
        })
    }

    renderIsSent() {
        if (this.state.hasSendingError) {
            return <div className="sent-modal">
                <h2>Something went wrong</h2>
                <p className={"contactus-sent-message"}>
                    You can reach us through email for your query at {EMAIL_ADDRESS}
                </p>
                <p className={"contactus-sent-close"} onClick={() => {
                    this.props.toggleScrollNavigation(true);
                    this.fileInput.current.value = null;
                    this.setState({
                        hasInitializedUpload: false,
                        uploadProgress: 0,
                        isSent: false,
                        isSending: false,
                        hasSendingError: false
                    })
                }}>Done</p>

            </div>
        } else if (this.state.isSent) {
            return <div className="sent-modal">
                <h2>Thank you</h2>
                <p className={"contactus-sent-message"}>
                    Our representative will reach out to you with your query.
                </p>
                <p className={"contactus-sent-close"} onClick={() => {
                    this.props.toggleScrollNavigation(true);
                    this.fileInput.current.value = null;
                    this.setState({
                        fileUpload: null,
                        from: '',
                        subject: '',
                        content: '',
                        hasInitializedUpload: false,
                        uploadProgress: 0,
                        isSent: false,
                        isSending: false
                    })
                }}>Done</p>
            </div>
        } else if (this.state.isSent || (this.state.isSending && !this.state.fileUpload)) {
            return <div className="sent-modal">
                <h2>Sending Query</h2>
                <p className={"contactus-sent-message"}>
                    Our representative will reach out to you with your query.
                </p>
            </div>
        }
    }

    renderIsSending() {
        if (!this.state.isSent && this.state.fileUpload && !this.state.hasSendingError) {
            const fileUploadName = this.state.fileUpload && this.state.fileUpload.name;
            const fileSize = getReadableFileSizeString(this.state.fileUpload.size);
            return <div className="sending-modal">
                {fileUploadName && <div className={"modal-filename-container"}>
                    <span className="modal-filesize">{fileSize}</span>
                    <span className="modal-filename">{fileUploadName}</span>
                </div>}
                <div className={"modal-progress-container"}>
                    <img id="modal-progress-guide-line" style={{width: "100%"}} src={GrayPixel} alt={""}/>
                    <img id="modal-progress-highlight-line" style={{
                        width: this.state.uploadProgress + "%"
                    }} src={RedPixel} alt={""}/>
                </div>
                <p className={"contactus-sent-close"} onClick={() => {
                    this.props.toggleScrollNavigation(true);
                    this.setState({
                        hasInitializedUpload: false,
                        uploadProgress: 0,
                        isSent: false,
                        isSending: false,
                        isCancelled: true
                    })
                }}>Cancel</p>
            </div>
        }
    }

    renderSendModal() {
        if (this.state.isSent || this.state.isSending || this.state.hasInitializedUpload || this.state.hasSendingError) {
            return ReactDOM.createPortal(<div>
                <div className="card-full-bg"/>
                {this.renderIsSent()}
                {this.renderIsSending()}
            </div>, document.getElementById('app-modal'));
        }
    }

    renderErrorFileSizeModal() {
        if (this.state.fileUpload && this.state.fileUpload.size > MAX_FILE_SIZE) {
            return ReactDOM.createPortal(<div>
                <div className="card-full-bg"/>
                <div className="error-modal">
                    <div className="modal-filename-container">
                        <span className="modal-filename">{this.state.fileUpload.name}</span>
                    </div>
                    <p>Attachment is too large. Max size: 25mb</p>
                    <p className={"contactus-sent-close"}>
                        <span  onClick={() => {
                            this.setState({fileUpload: null}, () => {
                                this.props.toggleScrollNavigation(true);
                                this.fileInput.current.value = null;
                                this.fileInput.current.click();
                            });
                        }}>Select File</span>
                        <span  onClick={() => {
                            this.setState({fileUpload: null}, () => {
                                this.props.toggleScrollNavigation(true);
                                this.fileInput.current.value = null;
                            });
                        }}>Cancel</span>
                    </p>
                </div>
            </div>, document.getElementById('app-modal'));
        }
    }

    render() {
        const fileUploadName = this.state.fileUpload && this.state.fileUpload.name;
        const isEmailValid = validateEmail(this.state.from.trim())
        const isFileValid = !this.state.fileUpload || this.state.fileUpload.size <= MAX_FILE_SIZE;
        const isFormValid = isEmailValid && isFileValid && this.state.subject.trim() && this.state.content.trim();
        return <div>
            <div className={"slide-up-3 fade-in"}>
                <input
                    type="email" placeholder="Email" value={this.state.from}
                    onBlur={(e) => {
                        const _this = this;
                        const element = e.target;
                        window.setTimeout(() => {
                            if (!(isEmailValid || !this.state.from)) {
                                TweenMax.to(element, 0.06, {x: "+=10", yoyo: true, repeat: 3});
                                TweenMax.to(element, 0.06, {x: "-=10", yoyo: true, repeat: 2});
                            }
                        }, 100)
                    }}
                    className={(isEmailValid || !this.state.from ? "valid" : "invalid") + " email-input"}
                    onChange={(e) => {
                        this.setState({from: e.target.value})
                    }}
                />
            </div>
            <div className={"slide-up-2 fade-in"}>
                <input
                    type="text"
                    placeholder="Subject"
                    value={this.state.subject}
                    onChange={(e) => {
                        this.setState({subject: e.target.value})
                    }}
                />
            </div>
            <div className={"slide-up-1 fade-in " + (fileUploadName ? 'textarea-has-file' : '')}>
                <textarea placeholder="Describe what you need" onChange={(e) => {
                    this.setState({content: e.target.value})
                }} value={this.state.content}/>
            </div>
            {fileUploadName && <div className={"file-upload-filename-container"}>
                <img src={XButton} id="cancel-upload-file" onClick={() => {
                    this.setState({fileUpload: null});
                    this.fileInput.current.value = null;
                }}/>
                <span className="file-upload-filename">{fileUploadName}</span>
            </div>}
            <div className={"form-actions"} style={{textAlign: "right", marginTop: "10px", overflow: "visible"}}>
                <label htmlFor="upload-attachment-file">
                    <img alt="Upload" src={UploadButton} style={{verticalAlign: "bottom", paddingRight: "20px"}}
                         id={"upload"} className={"fade-in"}/>
                </label>
                <input ref={this.fileInput} type="file" name="upload-attachment-file"
                       onChange={(event) => {
                           this.setState({
                               fileUpload: event.target.files[0],
                               // isSent: true,
                               // uploadProgress: 30
                           }, () => {
                               if (this.state.fileUpload && this.state.fileUpload.size > MAX_FILE_SIZE) {
                                   console.log("Filesize too large!")
                                   this.props.toggleScrollNavigation(false);
                               }
                           })
                       }}
                       className="hidden-file" id="upload-attachment-file"/>
                <button
                    disabled={!isFormValid}
                    className="form-btn cta fade-in"
                    type="button" id={"submit"}
                    onClick={this.sendContactUsForm.bind(this)}>Submit
                </button>
            </div>


            <h1>Start Sourcing With Us</h1>
            <p>
                <img alt={"Mail Icon"} className={"icon"} src={mailIcon}/>
                <span>
                    {EMAIL_ADDRESS}
                </span>
            </p>
            {/*<p>*/}
            {/*    <img alt={"Phone Icon"} className={"icon"} src={phoneIcon}/> +63923-1234567*/}
            {/*</p>*/}
            <div className={"p"} style={{display: "flex"}}>
                <div className={"address"}>
                    <img alt="Address Icon" className={"icon"} src={addressIcon}/>
                </div>
                <div className={"address"}>
                    Unit 2C F1 Hotel Manila, 32nd st cor Lane A, Bonifacio Global City, Taguig City, Metro Manila 1634
                </div>
            </div>

            {this.renderSendModal()}
            {this.renderErrorFileSizeModal()}
        </div>
    }
}

export const contactUs = <ContactUs/>