import * as React from "react";

function SvgLoadingLogoE(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={23}
      height={95.312}
      viewBox="0 0 19.698 95.312"
      {...props}
    >
      <path d="M0 47.575V2.209C0 .018 0 .016 2.163.016H17.75c.42 0 .936-.089 1.234.116a1.706 1.706 0 01.707 1.159c.01.314-.44.743-.788.935a2.5 2.5 0 01-1.12.08q-6.653.005-13.306 0c-2.184 0-2.2.01-2.2 2.175v71.091c0 1.69.036 3.38-.014 5.068-.033 1.1.43 1.514 1.507 1.488 1.857-.044 3.717 0 5.575-.016.927-.009 1.631.261 1.6 1.324s-.891.962-1.6.969c-1.858.019-3.718.054-5.575-.011-1.126-.04-1.512.453-1.5 1.5q.036 2.851 0 5.7c-.011 1.007.4 1.423 1.446 1.414 4.6-.037 9.208-.017 13.813-.015a3.153 3.153 0 011.251.065c.386.181.933.638.906.929a1.916 1.916 0 01-.839 1.2c-.3.2-.818.09-1.239.09q-7.856.005-15.713 0c-1.808 0-1.9-.095-1.9-1.961L0 47.575z" />
    </svg>
  );
}

export default SvgLoadingLogoE;
