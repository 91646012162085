import BackgroundBase from "../../components/BackgroundBase";

import BackgroundCenter from "./4/Image 78-fs8.png";
import MainOutline from "./4/Image 72-fs8.png";
import MainImage from "./4/Image 71-fs8.png";
import MainImageHighlight from "./4/Image 73-fs8.png";
// import MainImageForegroundShadow from "./4/Image 60-fs8.png";
import ForegroundShadow from "./4/Image 70-fs8.png";
import Foreground2Image from "./4/Image 68-fs8.png";
import Foreground3Image from "./4/Image 67-fs8.png";
import ForegroundHighlight from "./4/Image 69-fs8.png";

import BackgroundLeft1Image from "./4/Image 74-fs8.png";
import BackgroundLeft1Outline from "./4/Image 77-fs8.png";
import BackgroundRight1Image from "./4/Image 75-fs8.png";
import BackgroundRight1Outline from "./4/Image 76-fs8.png";

import BackgroundLineCircle from "./4/Ellipse 10.svg";
import BackgroundLineLeft from "./4/Path 95.svg";
import BackgroundLineRight from "./4/Path 96.svg";

import {TimelineLite} from 'gsap/all';
import {DESKTOP_BREAKPOINT, step1Duration, step2Delay, step2Duration} from "../../constants";
import React from "react";
import StaticBg from "./BG-4-fs8.png";

export default class Background4 extends BackgroundBase {
    introMobile() {
        new TimelineLite().fromTo(["#background4 .background-center"], {
            y: "41px"
        }, {
            duration: step1Duration,
            y: 0
        }).to(["#background4 .background-center"], {
            duration: step2Duration,
            delay: step2Delay,
            y: "11px"
        })

        new TimelineLite().fromTo(["#background4 .center-image"], {
            width: "191px",
            y: "5px",
            x: "5px"
        }, {
            duration: step1Duration,
            width: "200px",
            y: "0px",
            x: "0px"
        }).to(["#background4 .center-image"], {
            duration: step2Duration,
            delay: step2Delay,
            width: "218px",
            x: "-9px",
            y: "-23px"

        })

        new TimelineLite().fromTo(["#background4 .center-image-outline"], {
            width: "189px",
            y: "10px",
            x: "11px"
        }, {
            duration: step1Duration,
            width: "210px",
            y: "0px",
            x: "0px"
        }).to(["#background4 .center-image-outline"], {
            duration: step2Duration,
            delay: step2Delay,
            width: "228px",
            x: "-9px",
            y: "-23px"

        })

        new TimelineLite().fromTo(["#background4 .center-image-highlight"], {
            width: "193px",
            y: "4px",
            x: "5px"
        }, {
            duration: step1Duration,
            width: "203px",
            y: "0px",
            x: "0px"
        }).to(["#background4 .center-image-highlight"], {
            duration: step2Duration,
            delay: step2Delay,
            width: "221px",
            x: "-9px",
            y: "-26px"

        })

        new TimelineLite().fromTo(["#background4 .bottom"], {
            y: "-16px",
            x: "0px",
            width: "675px"
        }, {
            duration: step1Duration,
            y: "0px",
            x: "0px",
            width: "675px"
        }).to(["#background4 .bottom"], {
            duration: step2Duration,
            delay: step2Delay,
            y: "-7px",
            x: "-24px",
            width: "724px"
        })


        new TimelineLite().fromTo(["#background4 .bg-left"], {
            y: "11px",
            x: "11px",
            width: "262px"
        }, {
            duration: step1Duration,
            y: "0px",
            x: "0px",
            width: "273px"
        }).to(["#background4 .bg-left"], {
            duration: step2Duration,
            delay: step2Delay,
            x: "-15px",
        })

        new TimelineLite().fromTo(["#background4 .bg-right"], {
            y: "11px",
            x: "-11px",
            width: "262px"
        }, {
            duration: step1Duration,
            y: "0px",
            x: "0px",
            width: "274px"
        }).to(["#background4 .bg-right"], {
            duration: step2Duration,
            delay: step2Delay,
            x: "15px",
        })
        new TimelineLite().fromTo(["#background4 .bg-left-outline"], {
            y: "21px",
            x: "10px",
            width: "178px"
        }, {
            duration: step1Duration,
            y: "0px",
            x: "0px",
            width: "184px"
        }).to(["#background4 .bg-left-outline"], {
            duration: step2Duration,
            delay: step2Delay,
            x: "-15px",
        })

        new TimelineLite().fromTo(["#background4 .bg-right-outline"], {
            y: "25px",
            x: "-15px",
            width: "176px"
        }, {
            duration: step1Duration,
            y: "0px",
            x: "0px",
            width: "183px"
        }).to(["#background4 .bg-right-outline"], {
            duration: step2Duration,
            delay: step2Delay,
            x: "15px",
        })

        new TimelineLite().to(["#background4 .bg-line-left"], {
            duration: step2Duration,
            delay: step2Delay + step1Duration,
            x: "-15px",
        })

        new TimelineLite().to(["#background4 .bg-line-right"], {
            duration: step2Duration,
            delay: step2Delay + step1Duration,
            x: "15px",
        })
    }

    introDesktop() {
        const [bgWidth, bgHeight] = this.desktopBGSize()
        new TimelineLite().fromTo(['#background4 .static-background'], {
            width: "calc(10vw + " + bgWidth + "px)",
            x: "-5vw",
            y: "8vw"
        }, {
            width: bgWidth + "px",
            x: 0,
            y: 0,
            duration: step1Duration
        })
    }

    desktopBGSize() {
        const originalWidth = 2929;
        const originalHeight = 1690;
        const bgWidth = this.state.width;
        const bgHeight = originalHeight * (this.state.width / originalWidth);
        if (this.state.height + 70 > bgHeight) {
            return [originalWidth * ((this.state.height + 70) / originalHeight), this.state.height + 70]
        }
        return [bgWidth, bgHeight]
    }

    render() {
        if (!this.state.width) {
            return <></>
        }
        if (this.state.width >= DESKTOP_BREAKPOINT) {
            const [bgWidth, bgHeight] = this.desktopBGSize()
            return <div id={"background4"}>
                <img src={StaticBg} alt="" className={"static-background"} style={{
                    bottom: "-70px",
                    left: "calc(50vw - " + (bgWidth/2) + "px)",
                    width: bgWidth + "px",
                }}/>
            </div>
        }
        return <div id="background4">
            <img  alt="" className={"bg-line-circle"} src={BackgroundLineCircle} style={{bottom: "67px", left: "calc(50vw - 214.5px"}}/>
            <img  alt="" className={"bg-line-left"} src={BackgroundLineLeft} style={{bottom: "252px", left: "calc(50vw - 530px"}}/>
            <img  alt="" className={"bg-line-right"} src={BackgroundLineRight} style={{bottom: "259.22px", left: "calc(50vw - 10px)"}}/>

            <img  alt="" className={"bg-left-outline"} src={BackgroundLeft1Outline} style={{bottom: "282px", left: "calc(50vw - 263.5px)"}}/>
            <img  alt="" className={"bg-left"} src={BackgroundLeft1Image} style={{bottom: "68px", left: "calc(50vw - 271.5px)"}}/>

            <img  alt="" className={"bg-right-outline"} src={BackgroundRight1Outline} style={{bottom: "282px", left: "calc(50vw + 78px)"}}/>
            <img  alt="" className={"bg-right"}  src={BackgroundRight1Image} style={{bottom: "68px", left: "calc(50vw - 4px)"}}/>

            <img  alt="" className={"background-center"} src={BackgroundCenter} style={{bottom: "307px", left: "calc(50vw - 89.5px)"}}/>

            <img  alt="" className={"center-image-outline"} src={MainOutline} style={{bottom: "128px", left: "calc(50vw - 104.5px)"}}/>
            <img  alt="" className={"center-image"} src={MainImage} style={{bottom: "110px", left: "calc(50vw - 99.5px)"}}/>
            <img  alt="" className={"center-image-highlight"} src={MainImageHighlight} style={{bottom: "167px", left: "calc(50vw - 99.5px)"}}/>

            <img  alt="" src={Foreground2Image} style={{bottom: "60px", left: "calc(50vw - 177.5px)"}}/>
            <img  alt="" src={ForegroundShadow} style={{bottom: "60px", left: "calc(50vw - 163.5px)"}}/>
            <img  alt="" className={"bottom"} src={Foreground3Image} style={{bottom: "-16px", left: "calc(50vw - 337.5px)"}}/>
            <img  alt="" src={ForegroundHighlight} style={{bottom: "147px", left: "calc(50vw - 162.5px)"}}/>

            {/*<div className={"white-gradient"} style={{opacity: 0, left: "calc(-50vw)"}} onClick={() => this.intro()}/>*/}
            {/*<div className={"white-overlay"}  onClick={() => this.intro()}/>*/}
        </div>
    }
}