import Axios from "axios";

Axios.defaults.baseURL = 'https://3n4f2volii.execute-api.ap-southeast-1.amazonaws.com/develop'

export const uploadFileToS3 = (presignedPostData, file, axiosConfig) => {
    // create a form obj
    const formData = new FormData();

    // append the fields in presignedPostData in formData
    Object.keys(presignedPostData.fields).forEach(key => {
        formData.append(key, presignedPostData.fields[key]);
    });

    // append the file
    formData.append("file", file);

    // post the data on the s3 url
    return Axios.post(presignedPostData.url, formData, {
        ...axiosConfig,
        headers: {
            'Content-Type': 'application/octet-stream'
        }
    })
};

export const getPresignedPostData = (file, recaptchaToken) => {
    const filename = file.name
    return Axios.post('/start_upload/', {
        "filename": filename,
        "recaptcha_token": recaptchaToken
    })
}

export const sendContactUs = (from, subject, content, temporaryPath, filename, recaptchaToken) => {
    return Axios.post('/contact_us/', {
        recaptcha_token: recaptchaToken,
        from: from,
        subject: subject,
        content: content,
        tmp_path: temporaryPath,
        filename: filename
    })
}

export function getReadableFileSizeString(fileSizeInBytes) {
    let i = -1;
    const byteUnits = [' kB', ' MB', ' GB', ' TB', 'PB', 'EB', 'ZB', 'YB'];
    do {
        fileSizeInBytes = fileSizeInBytes / 1024;
        i++;
    } while (fileSizeInBytes > 1024);

    return Math.max(fileSizeInBytes, 0.1).toFixed(1) + byteUnits[i];
}

export function validateEmail(email) {
    const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
}