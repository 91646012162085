import * as React from "react";

function SvgLoadingLogoU3(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={23}
      height={94}
      viewBox="0 0 22 94"
      {...props}
    >
      <path
        d="M1 93V1l20 36.943V93"
        fill="none"
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
      />
    </svg>
  );
}

export default SvgLoadingLogoU3;
