import BackgroundBase from "../../components/BackgroundBase";

import MainOutline from "./3/Image 58-fs8.png";
import MainImage from "./3/Image 57-fs8.png";
import MainImageHighlight from "./3/Image 59-fs8.png";
import MainImageForegroundShadow from "./3/Image 60-fs8.png";

import ForegroundLeft1Image from "./3/Image 63-fs8.png";
import ForegroundLeft2Image from "./3/Image 62-fs8.png";
import ForegroundRight1Image from "./3/Image 64-fs8.png";
import ForegroundRight2Image from "./3/Image 65-fs8.png";

import BackgroundLeft1Image from "./3/Image 53-fs8.png";
import BackgroundLeft2Image from "./3/Image 55-fs8.png";
import BackgroundRight1Image from "./3/Image 54-fs8.png";
import BackgroundRight2Image from "./3/Image 56-fs8.png";

import BackgroundLine1 from "./3/Path 92.svg";
import BackgroundLine2 from "./3/Path 93.svg";
import BackgroundLine3 from "./3/Path 94.svg";

import {TimelineLite} from 'gsap/all';
import {DESKTOP_BREAKPOINT, step1Duration, step2Delay, step2Duration} from "../../constants";
import React from "react";
import StaticBg from "./BG-3-fs8.png";

export default class Background3 extends BackgroundBase {
    introMobile() {
        this.swipeUp(['#background3 .main-outline', '#background3 .bg-line', ], 0, step1Duration)
        this.swipeUp(['#background3 .bg-line-center'], step1Duration/4, step1Duration )

        new TimelineLite().fromTo(["#background3 .main-outline"], {
            width: "144px",
            x: "5px",
        }, {
            duration: step1Duration,
            width: "160px",
            x: "0px",
        }).to(["#background3 .main-outline"], {
            duration: step2Duration,
            delay: step2Delay,
            width: "171px",
            x: "-8px"
        })

        new TimelineLite().fromTo(["#background3 .main-image"], {
            width: "207px",
            x: "15px",
        }, {
            duration: step1Duration,
            width: "232px",
            x: "0px",
        }).to(["#background3 .main-image"], {
            duration: step2Duration,
            delay: step2Delay,
            width: "247px",
            x: "-10px"
        })

        new TimelineLite().fromTo(["#background3 .main-image-highlight"], {
            width: "142px",
            x: "5px",
            y: "10px",
        }, {
            duration: step1Duration,
            width: "158px",
            x: "0px",
            y: "0px",
        }).to(["#background3 .main-image-highlight"], {
            duration: step2Duration,
            delay: step2Delay,
            width: "166px",
            x: "-5px",
            y: "-7px"
        })

        // backgrounds
        new TimelineLite().fromTo(["#background3 .bg-left"], {
            width: "159px",
            x: "45px"
        }, {
            duration: step1Duration,
            width: "187px",
            x: "0px"
        }).to(["#background3 .bg-left"], {
            duration: step2Duration,
            delay: step2Delay,
            x: "-17px"
        })
        new TimelineLite().fromTo(["#background3 .bg-right"], {
            width: "159px",
            x: "-18px"
        }, {
            duration: step1Duration,
            width: "186px",
            x: "0px"
        }).to(['#background3 .bg-right'], {
            duration: step2Duration,
            delay: step2Delay,
            x: "13px"
        })

        new TimelineLite().fromTo(["#background3 .bg-left-2"], {
            y: "40px"
        }, {
            duration: step1Duration,
            y: "0px"
        }).to(["#background3 .bg-left-2"], {
            duration: step2Duration,
            delay: step2Delay,
            x: "-17px"
        })
        new TimelineLite().fromTo(["#background3 .bg-right-2"], {
            y: "40px"
        }, {
            duration: step1Duration,
            y: "0px"
        }).to(['#background3 .bg-right-2'], {
            duration: step2Duration,
            delay: step2Delay,
            x: "19px"
        })

        // foregrounds
        new TimelineLite().fromTo(['#background3 .fg-left-1'], {
            width: "139px",
            x: "50px"
        }, {
            duration: step1Duration,
            width: "157px",
            x: "0px"
        }).to(['#background3 .fg-left-1'], {
            duration: step2Duration,
            delay: step2Delay,
            x: "-11px"
        })
        new TimelineLite().fromTo(['#background3 .fg-left-2'], {
            duration: step1Duration,
            width: "64px",
            x: "2px"
        }, {
            duration: step1Duration,
            width: "71px",
            x: "0px"
        }).to(['#background3 .fg-left-2'], {
            duration: step2Duration,
            delay: step2Delay,
            x: "-10px"
        })

        new TimelineLite().fromTo(['#background3 .fg-right-1'], {
            duration: step1Duration,
            width: "104px",
            x: "-20px"
        }, {
            duration: step1Duration,
            width: "114px",
            x: "0px"
        }).to(['#background3 .fg-right-1'], {
            duration: step2Duration,
            delay: step2Delay,
            x: "14px"
        })
        new TimelineLite().fromTo(['#background3 .fg-right-2'], {
            y: "40px"
        }, {
            duration: step1Duration,
            y: "0px"
        }).to(['#background3 .fg-right-2'], {
            duration: step2Duration,
            delay: step2Delay,
            width: "148px"
        })


    }

    introDesktop() {
        const [bgWidth, bgHeight] = this.desktopBGSize()
        new TimelineLite().fromTo(['#background3 .static-background'], {
            width: "calc(10vw + " + bgWidth + "px)",
            x: "-5vw",
            y: "15vw"
        }, {
            width: bgWidth + "px",
            x: 0,
            y: 0,
            duration: step1Duration
        })
    }

    desktopBGSize() {
        const originalWidth = 2021;
        const originalHeight = 1690;

        return [originalWidth * ((this.state.height + 140) / originalHeight), this.state.height + 140];
    }

    render() {
        if (!this.state.width) {
            return <></>
        }
        if (this.state.width >= DESKTOP_BREAKPOINT) {
            const [bgWidth, bgHeight] = this.desktopBGSize()
            return <div id={"background3"}>
                <img src={StaticBg} alt="" className={"static-background"} style={{
                    bottom: "-70px",
                    left: "calc(50vw - " + (bgWidth/2) + "px)",
                    width: bgWidth + "px",
                    // height: bgHeight + "px",
                }}/>
            </div>
        }

        return <div id="background3">
            <img  alt="" className={"bg-line"} src={BackgroundLine1} style={{bottom: "0px", left: "calc(50vw - 150.33px"}}/>
            <img  alt="" className={"bg-line-center"} src={BackgroundLine2} style={{bottom: "0px", left: "calc(50vw - 12px"}}/>
            <img  alt="" className={"bg-line"} src={BackgroundLine3} style={{bottom: "0px", left: "calc(50vw - 348.55px"}}/>

            <img  alt="" className="bg-left-2" src={BackgroundLeft1Image} style={{bottom: "0px", left: "calc(50vw - 432px)"}}/>
            <img  alt="" className="bg-left" src={BackgroundLeft2Image} style={{bottom: "0px", left: "calc(50vw - 193px)"}}/>

            <img  alt="" className="bg-right-2" src={BackgroundRight1Image} style={{bottom: "0px", left: "calc(50vw + 66px)"}}/>
            <img  alt="" className="bg-right" src={BackgroundRight2Image} style={{bottom: "-0px", left: "calc(50vw - 14px)"}}/>

            <img  alt="" className={"main-outline"} src={MainOutline} style={{bottom: "2px", left: "calc(50vw - 87px)"}}/>
            <img  alt="" className={"main-image"} src={MainImage} style={{bottom: "0px", left: "calc(50vw - 146px)"}}/>
            <img  alt="" className={"main-image-highlight"} src={MainImageHighlight}
                 style={{bottom: "69px", left: "calc(50vw - 86px)"}}/>
            <img  alt="" className={"main-foreground-shadow"} src={MainImageForegroundShadow}
                 style={{bottom: "0px", left: "calc(50vw - 130px)"}}/>

            <img  alt="" className="fg-left-2" src={ForegroundLeft2Image} style={{bottom: "0px", left: "calc(50vw - 135px)"}}/>
            <img  alt="" className="fg-left-1" src={ForegroundLeft1Image} style={{bottom: "0px", left: "calc(50vw - 288px)"}}/>

            <img  alt="" className="fg-right-1" src={ForegroundRight1Image} style={{bottom: "0px", left: "calc(50vw + 91px)"}}/>
            <img  alt="" className="fg-right-2" src={ForegroundRight2Image}
                 style={{bottom: "-0px", left: "calc(50vw - 38px)"}}/>

            {/*<div className={"white-gradient"} style={{opacity: 0, left: "calc(-50vw)"}} onClick={() => this.intro()}/>*/}
            {/*<div className={"white-overlay"}  onClick={() => this.intro()}/>*/}
        </div>
    }
}