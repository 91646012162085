import SvgLoadingLogoD from "../assets/loading/LoadingLogoD";
import SvgLoadingLogoD1 from "../assets/loading/LoadingLogoD1";
import SvgLoadingLogoD2 from "../assets/loading/LoadingLogoD2";
import SvgLoadingLogoD3 from "../assets/loading/LoadingLogoD3";

import SvgLoadingLogoE from "../assets/loading/LoadingLogoE";
import SvgLoadingLogoE1 from "../assets/loading/LoadingLogoE1";
import SvgLoadingLogoE2 from "../assets/loading/LoadingLogoE2";
import SvgLoadingLogoE3 from "../assets/loading/LoadingLogoE3";

import SvgLoadingLogoC from "../assets/loading/LoadingLogoC";
import SvgLoadingLogoC1 from "../assets/loading/LoadingLogoC1";
import SvgLoadingLogoC2 from "../assets/loading/LoadingLogoC2";
import SvgLoadingLogoC3 from "../assets/loading/LoadingLogoC3";

import SvgLoadingLogoU from "../assets/loading/LoadingLogoU";
import SvgLoadingLogoU1 from "../assets/loading/LoadingLogoU1";
import SvgLoadingLogoU2 from "../assets/loading/LoadingLogoU2";
import SvgLoadingLogoU3 from "../assets/loading/LoadingLogoU3";

import SvgLoadingLogoS from "../assets/loading/LoadingLogoS";
import SvgLoadingLogoS1 from "../assets/loading/LoadingLogoS1";
import SvgLoadingLogoS2 from "../assets/loading/LoadingLogoS2";
import SvgLoadingLogoS3 from "../assets/loading/LoadingLogoS3";

export const LOADING_LETTER_INDEX = {
    d: 0,
    e: 1,
    c: 2,
    u: 3,
    s: 4
}

export const LOADING_LETTERS = {
    d: [
        <SvgLoadingLogoD/>,
        <SvgLoadingLogoD1/>,
        <SvgLoadingLogoD2/>,
        <SvgLoadingLogoD3/>
    ],
    e: [
        <SvgLoadingLogoE/>,
        <SvgLoadingLogoE1/>,
        <SvgLoadingLogoE2/>,
        <SvgLoadingLogoE3/>
    ],
    c: [
        <SvgLoadingLogoC/>,
        <SvgLoadingLogoC1/>,
        <SvgLoadingLogoC2/>,
        <SvgLoadingLogoC3/>
    ],
    u: [
        <SvgLoadingLogoU/>,
        <SvgLoadingLogoU1/>,
        <SvgLoadingLogoU2/>,
        <SvgLoadingLogoU3/>
    ],
    s: [
        <SvgLoadingLogoS/>,
        <SvgLoadingLogoS1/>,
        <SvgLoadingLogoS2/>,
        <SvgLoadingLogoS3/>
    ],
}