import * as React from "react";

function SvgLoadingLogoS3(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={23}
      height={94.771}
      viewBox="0 0 22.771 94.771"
      {...props}
    >
      <path
        d="M21.382 1.389l-20 29.236 20 31.787-20 30.977"
        fill="none"
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
      />
    </svg>
  );
}

export default SvgLoadingLogoS3;
