import * as React from "react";

function SvgLogoPartialLeft(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={27.389}
      height={52.78}
      {...props}
    >
      <path d="M26.373 52.777a.984.984 0 01-.7-.289L.282 27.097a.987.987 0 010-1.4L25.697.282a.99.99 0 011.4 1.4L2.383 26.402l24.69 24.693a.987.987 0 01-.7 1.685z" />
    </svg>
  );
}

export default SvgLogoPartialLeft;
