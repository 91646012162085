import * as React from "react";

function SvgLoadingLogoC(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={23}
      height={95.278}
      viewBox="0 0 19.828 95.278"
      {...props}
    >
      <path d="M.003 47.541V2.05C.003.02.02.002 2.042.001h15.84c1.794 0 1.928.146 1.929 1.96 0 3.295.029 6.59-.03 9.883a1.87 1.87 0 01-.678 1.415c-.938.5-1.572 0-1.583-1.166-.026-2.661 0-5.322-.01-7.983 0-1.7-.094-1.806-1.78-1.811q-5.829-.018-11.658 0c-1.677 0-1.769.087-1.77 1.81q-.008 13.622 0 27.244v59.175c0 .338.02.677 0 1.013-.069 1.086.413 1.473 1.508 1.46q6.082-.072 12.164 0c1.154.013 1.577-.419 1.556-1.556-.048-2.576-.027-5.153-.013-7.73 0-.756-.071-1.625 1.1-1.588 1.027.032 1.207.714 1.2 1.568-.01 3.337-.028 6.674.01 10.01.013 1.157-.49 1.577-1.6 1.573q-8.426-.032-16.853 0c-.985 0-1.381-.416-1.374-1.357.013-1.732.005-3.463.005-5.2Q.003 68.133.003 47.541z" />
    </svg>
  );
}

export default SvgLoadingLogoC;
