import * as React from "react";

function SvgRune2(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 15.53 31"
      {...props}
    >
      <g fill="none" strokeLinecap="round" strokeLinejoin="round">
        <path d="M1.5 30.5V.5l13.53 12.027V30.5" stroke="#8f1fff" />
        <path d="M.5 30.5V.5l13.53 12.027V30.5" stroke="#00b0f1" />
      </g>
    </svg>
  );
}

export default SvgRune2;
