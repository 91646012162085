import * as React from "react";

function SvgRune3(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 12.425 31"
      {...props}
    >
      <path
        d="M1.707 30.5V.5l10.218 8.6-10.218 9.993"
        stroke="#00e2f1"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M.707 30.5V.5l10.218 8.6L.707 19.093"
        stroke="#ff751f"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default SvgRune3;
