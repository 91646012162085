import BackgroundBase from "../../components/BackgroundBase";

import BottomImage from "./2/Image 44-fs8.png";
import MainOutline from "./2/Image 79-fs8.png";
import MainImage from "./2/Image 50-fs8.png";
import MainImageHighlight from "./2/Image 51-fs8.png";
import Left1Image from "./2/Image 45-fs8.png";
import Left2Image from "./2/Image 47-fs8.png";
import Right1Image from "./2/Image 48-fs8.png";
import Right2Image from "./2/Image 46-fs8.png";

import LineLeft from "./2/Path 89.svg";
import LineRight from "./2/Path 90.svg";

import {TimelineLite} from 'gsap/all';
import {DESKTOP_BREAKPOINT, step1Duration, step2Delay, step2Duration} from "../../constants";
import React from "react";
import StaticBg from "./BG-2-fs8.png";


export default class Background2 extends BackgroundBase {
    introMobile() {
        new TimelineLite().fromTo(['#background2 .main-image-highlight'], {
            width: "205px",
            x: "5px",
            y: "5px"
        }, {
            duration: step1Duration,
            width: "216px",
            x: "0px",
            y: "0px"
        }).to(['#background2 .main-image-highlight'], {
            delay: step2Delay,
            duration: step1Duration,
            width: "230px",
            x: "-5.5px",
            y: "5.5px"
        });
        new TimelineLite().fromTo(['#background2 .main-image'], {
            width: "324px",
            x: "9px",
            y: "0px"
        }, {
            duration: step1Duration,
            width: "342px",
            x: "0px",
            y: "0px"
        }).to(['#background2 .main-image'], {
            delay: step2Delay,
            duration: step1Duration,
            width: "364px",
            x: "-10px",
            y: "12px"
        })

        new TimelineLite().fromTo(['#background2 .main-image-outline'], {
            width: "332px",
            x: "9px",
        }, {
            duration: step1Duration,
            width: "350px",
            x: "0px",
        }).to(['#background2 .main-image-outline'], {
            delay: step2Delay,
            duration: step1Duration,
            width: "374px",
            x: "-10px",
            y: "12px"
        })

        // foreground
        new TimelineLite().fromTo(['#background2 .foreground'], {
            y: "8px",
        }, {
            duration: step1Duration,
            y: "0px"
        }).to(['#background2 .foreground'], {
            delay: step2Delay,
            duration: step2Duration,
            y: "5px"
        })

        // background elements
        new TimelineLite().fromTo(['#background2 .bg-right-front'], {
            x: "-43px",
            y: "10px",
        }, {
            duration: step1Duration,
            x: "0px",
            y: "0px",
        }).to(['#background2 .bg-right-front'], {
            delay: step2Delay,
            duration: step2Duration,
            x: "29px"
        })
        new TimelineLite().fromTo(['#background2 .bg-right-back'], {
            x: "-43px",
            y: "10px",
            width: "267px"
        }, {
            duration: step1Duration,
            x: "0px",
            y: "0px",
            width: "298px"
        }).to(['#background2 .bg-right-back'], {
            delay: step2Delay,
            duration: step2Duration,
            y: "5px"
        })


        new TimelineLite().fromTo(['#background2 .bg-left-front'], {
            x: "43px",
            y: "10px",
        }, {
            duration: step1Duration,
            x: "0px",
            y: "0px",
        }).to(['#background2 .bg-left-front'], {
            delay: step2Delay,
            duration: step2Duration,
            x: "-26px"
        })
        new TimelineLite().fromTo(['#background2 .bg-left-back'], {
            x: "43px",
            y: "10px",
            width: "267px"
        }, {
            duration: step1Duration,
            x: "0px",
            y: "0px",
            width: "297px"
        }).to(['#background2 .bg-left-back'], {
            delay: step2Delay,
            duration: step2Duration,
            y: "5px"
        });


        // this.swipeUp(['#background2 .main-image-outline', '#background2 .bg-line', ], 0, step1Duration)

    }

    introDesktop() {
        const [bgWidth, bgHeight] = this.desktopBGSize()
        new TimelineLite().fromTo(['#background2 .static-background'], {
            width: "calc(20vw + " + bgWidth + "px)",
            x: "-10vw",
            y: "8vw"
        }, {
            width: bgWidth + "px",
            x: 0,
            y: 0,
            duration: step1Duration
        })
    }

    desktopBGSize() {
        const originalWidth = 2952;
        const originalHeight = 1690;

        const bgHeight = this.state.height + 140;
        const bgWidth = originalWidth * ((this.state.height + 140) / originalHeight)

        if (this.state.width > bgWidth) {
            return [this.state.width, originalHeight * (this.state.width / originalWidth)];
        }
        return [bgWidth, bgHeight]
    }

    render() {

        if (!this.state.width) {
            return <></>
        }
        if (this.state.width >= DESKTOP_BREAKPOINT) {
            const [bgWidth, bgHeight] = this.desktopBGSize()

            return <div id={"background2"}>
                <img src={StaticBg} alt="" className={"static-background"} style={{
                    top: "calc(50vh - " + (bgHeight/2) + "px)",
                    left: "calc(50vw - " + (bgWidth/2) + "px)",
                    width: bgWidth + "px",
                    // height: bgHeight + "px",
                }}/>
            </div>
        }

        return <div id="background2">
            <img  alt="" className={"bg-line line-right"} src={LineRight} style={{bottom: "300px",left: "calc(50vw + 30px)"}}/>
            <img  alt="" className={"bg-line line-left"} src={LineLeft} style={{bottom: "100px",left: "calc(50vw - 700px)"}}/>

            <img  alt="" className={"bg-left-back"} src={Left1Image} style={{bottom: "67px", left: "calc(50vw - 473.5px)"}}/>
            <img  alt="" className={"bg-left-front"} src={Left2Image} style={{bottom: "0px", left: "calc(50vw - 250px)"}}/>
            <img  alt="" className={"bg-right-back"} src={Right2Image} style={{bottom: "67px", left: "calc(50vw + 190px)"}}/>
            <img  alt="" className={"bg-right-front"} src={Right1Image} style={{bottom: "-1px", left: "calc(50vw - 12px)"}}/>

            <img  alt="" className={"main-image-outline"} src={MainOutline} style={{bottom: "127px", left: "calc(50vw - 174.5px)"}}/>
            <img  alt="" className={"main-image"} src={MainImage} style={{bottom: "134px", left: "calc(50vw - 170.5px)"}}/>
            <img  alt="" className={"main-image-highlight"} src={MainImageHighlight} style={{bottom: "244px", left: "calc(50vw - 100px)"}}/>

            <img  alt="" className={"foreground"} src={BottomImage}  style={{bottom: "0px", left: "calc(50vw - 485px)"}}/>

            {/*<div className={"white-gradient"} style={{opacity: 0, left: "calc(-50vw)"}} onClick={() => this.intro()}/>*/}
            {/*<div className={"white-overlay"}  onClick={() => this.intro()}/>*/}
        </div>
    }
}