import BackgroundBase from "../../components/BackgroundBase";

import BackgroundImageRight from "./1/Image 31-fs8.png";
import BackgroundImageLeft from "./1/Image 32-fs8.png";
import MainImage from "./1/Image 87-fs8.png";
import MainImageHighlight from "./1/Image 89-fs8.png"
import Path85 from "./1/Path 85.svg";
import Path86 from "./1/Path 86.svg";

import StaticBg from "./BG-1-fs8.png";

import {TimelineLite} from 'gsap/all';
import {DESKTOP_BREAKPOINT, step1Duration, step2Delay, step2Duration} from "../../constants";
import React from "react";

export default class Background1 extends BackgroundBase {
    introMobile() {
        const defaultAnimationOptions = {
            // repeat: -1
        }
        new TimelineLite().fromTo(['.bg-1-main'], {
            y: "0px",
            x: "0px"
        }, {
            y: "-10px",
            duration: step1Duration,
        }).to(['.bg-1-main'], {
            x: "5px",
            duration: step2Duration,
            delay: step2Delay
        });

        new TimelineLite().fromTo(['#bg-outline-mask-rect'], {
            y: -6000,
        }, {
            y: 0,
            duration: step1Duration,
        });
        new TimelineLite().fromTo(['.bg-1-left'], {
            height: '253px',
            x: "-30px",
        }, {
            height: '230px',
            x: "0px",
            duration: step1Duration
        }).to(['.bg-1-left'], {
            x: "-20px",
            duration: step2Duration,
            delay: step2Delay
        })
        new TimelineLite().fromTo(['.bg-1-right'], {
            height: "308px",
            x: "20px",
        }, {
            height: "279px",
            x: "0px",
            duration: step1Duration
        }).to(['.bg-1-right'], {
            x: "20px",
            duration: step2Duration,
            delay: step2Delay
        })
    }
    introDesktop() {
        const [bgWidth, bgHeight] = this.desktopBGSize()
        new TimelineLite().fromTo(['#background1 .static-background'], {
            // width: (bgWidth + 60) + "px",
            // x: -30,
            y: "10%"
        }, {
            // width: bgWidth + "px",
            // x: 0,
            y: 0,
            duration: step1Duration
        })
    }

    desktopBGSize() {

        const originalWidth = 2643;
        const originalHeight = 1690;

        const bgHeight = this.state.height;
        const bgWidth = originalWidth * ((this.state.height) / originalHeight)

        if (this.state.width > bgWidth) {
            return [this.state.width, originalHeight * (this.state.width / originalWidth)];
        }
        return [bgWidth, bgHeight]

    }

    render() {
        if (!this.state.width) {
            return <></>
        }
        if (this.state.width >= DESKTOP_BREAKPOINT) {
            const [bgWidth, bgHeight] = this.desktopBGSize()
            return <div id={"background1"}>
                <img src={StaticBg} alt="" className={"static-background"} style={{
                    top: "calc(50vh - " + (bgHeight/2) + "px)",
                    left: "calc(50vw - " + (bgWidth/2) + "px)",
                    width: bgWidth + "px",
                    // height: bgHeight + "px",
                }}/>
            </div>
        }
        return <div id="background1">
            <img alt="" src={Path85} style={{bottom: "0px", left: "calc(50vw - 310px)"}}/>
            <img alt=""  src={Path86} style={{bottom: "-55px", left: "calc(50vw - 110px)"}}/>

            <img alt=""  className="bg-1-right" src={BackgroundImageRight} style={{height: "279px", bottom: "0px", left: "calc(50vw + 90px)"}}/>
            <img alt=""  className="bg-1-left" src={BackgroundImageLeft} style={{height: "230px", bottom: "0px", left: "calc(50vw - 280px)"}}/>

            <svg className="bg-1-main bg-1-main-outline" width={301} height={601}
                 style={{bottom: "-10px", left: "calc(50vw - 155px)"}}>
                <defs>
                    <mask id="bg-outline-mask">
                        <rect id="bg-outline-mask-rect" height={6000} x={0} width={3000} y={0} fill='#fff'/>
                        {/*<rect id="bg-outline-mask-rect" height={600} x={0} width={300} y={0} fill='#fff'/>*/}
                    </mask>
                </defs>
                <g transform="translate(0.000000,601.000000) scale(0.100000,-0.100000)"
                   fill="#9C09FF" stroke="none" opacity={0.5}>

                    {/*<rect id="bg-outline-mask-rect" height={600} x={0} width={300} y={0} fill='#000'/>*/}

                    <path d="M2292 5748 c-132 -145 -280 -306 -330 -358 l-91 -95 -1 143 c0 176
-3 179 -98 95 -613 -541 -1331 -1180 -1346 -1199 -16 -20 -23 -58 -37 -216
-10 -106 -27 -290 -38 -408 -11 -118 -36 -386 -56 -595 -20 -209 -42 -447 -50
-530 -8 -82 -30 -321 -50 -530 -20 -209 -42 -450 -50 -536 -14 -146 -34 -363
-71 -744 -25 -270 -54 -577 -59 -645 -3 -36 -8 -77 -10 -92 l-4 -28 1500 0
1501 0 -6 28 c-3 15 -19 119 -36 232 -16 113 -32 216 -36 229 l-5 24 -34 -33
-33 -32 -6 63 c-3 35 -8 116 -11 179 -3 63 -12 248 -20 410 -20 395 -51 1016
-70 1390 -8 168 -22 433 -30 590 -8 157 -21 427 -29 600 -9 173 -18 351 -21
395 -3 44 -12 220 -20 390 -9 171 -22 445 -30 610 -8 165 -17 338 -20 385 -3
47 -10 179 -15 294 -10 195 -18 246 -40 246 -4 0 -116 -118 -248 -262z"/>
                </g>
                <g mask="url(#bg-outline-mask)" transform="translate(0.000000,601.000000) scale(0.100000,-0.100000)"
                   fill="#9C09FF" stroke="none">

                    {/*<rect id="bg-outline-mask-rect" height={600} x={0} width={300} y={0} fill='#000'/>*/}

                    <path d="M2292 5748 c-132 -145 -280 -306 -330 -358 l-91 -95 -1 143 c0 176
-3 179 -98 95 -613 -541 -1331 -1180 -1346 -1199 -16 -20 -23 -58 -37 -216
-10 -106 -27 -290 -38 -408 -11 -118 -36 -386 -56 -595 -20 -209 -42 -447 -50
-530 -8 -82 -30 -321 -50 -530 -20 -209 -42 -450 -50 -536 -14 -146 -34 -363
-71 -744 -25 -270 -54 -577 -59 -645 -3 -36 -8 -77 -10 -92 l-4 -28 1500 0
1501 0 -6 28 c-3 15 -19 119 -36 232 -16 113 -32 216 -36 229 l-5 24 -34 -33
-33 -32 -6 63 c-3 35 -8 116 -11 179 -3 63 -12 248 -20 410 -20 395 -51 1016
-70 1390 -8 168 -22 433 -30 590 -8 157 -21 427 -29 600 -9 173 -18 351 -21
395 -3 44 -12 220 -20 390 -9 171 -22 445 -30 610 -8 165 -17 338 -20 385 -3
47 -10 179 -15 294 -10 195 -18 246 -40 246 -4 0 -116 -118 -248 -262z"/>
                </g>
            </svg>
            <img alt="" className="bg-1-main" src={MainImage} style={{bottom: "-10px", left: "calc(50vw - 150px)"}}/>
            <img alt="" className="bg-1-main" src={MainImageHighlight} style={{bottom: "-10px", left: "calc(50vw - 150px)"}}/>


            {/*<div className={"white-gradient"} style={{opacity: 0, left: "calc(-50vw)"}} onClick={() => this.intro()}/>*/}
            {/*<div className={"white-overlay"}  onClick={() => this.intro()}/>*/}
        </div>
    }
}