import * as React from "react";

function SvgLoadingLogoE1(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={23}
      height={94.007}
      viewBox="0 0 22.007 94.007"
      {...props}
    >
      <path
        d="M1.612 93L1.001 1l9.447 20.027L20.43 1 21 93"
        fill="none"
        stroke="#00e2f1"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
      />
    </svg>
  );
}

export default SvgLoadingLogoE1;
