import * as React from "react";

function SvgLoadingLogoU(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={23}
      height={95.219}
      viewBox="0 0 19.869 95.219"
      {...props}
    >
      <path d="M19.838 47.505V93.12c0 2.026-.06 2.095-2.029 2.1H1.844c-1.68 0-1.815-.14-1.815-1.8V1.809c0-.337-.1-.78.073-.988A1.96 1.96 0 011.269.044c.331-.014.784.452 1 .814a2.44 2.44 0 01.066 1.12v88.823c0 2.13 0 2.134 2.077 2.135 3.886 0 7.772-.032 11.657.018 1.116.015 1.545-.408 1.477-1.478-.034-.547-.006-1.1-.006-1.647q0-43.905.01-87.81c0-.891-.357-2.165 1.265-2 .358.037.764.509.968.88a2.555 2.555 0 01.059 1.122q-.003 22.74-.004 45.484z" />
    </svg>
  );
}

export default SvgLoadingLogoU;
