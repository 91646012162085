import * as React from "react";

function SvgHeaderLeft(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={10.89}
      height={20.98}
      viewBox="0 0 14.514 27.97"
      {...props}
    >
      <path d="M13.979 27.97a.521.521 0 01-.37-.153L.153 14.362a.523.523 0 010-.74L13.622.153a.523.523 0 11.74.739l-13.1 13.1 13.087 13.085a.523.523 0 01-.37.893z" />
    </svg>
  );
}

export default SvgHeaderLeft;
