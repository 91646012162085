import * as React from "react";

function SvgLogoPartialRight(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={27.389}
      height={52.78}
      {...props}
    >
      <path d="M1.012 52.777a.987.987 0 01-.7-1.685l24.693-24.693L.287 1.681a.99.99 0 111.4-1.4l25.415 25.415a.987.987 0 010 1.4L1.711 52.487a.983.983 0 01-.699.29z" />
    </svg>
  );
}

export default SvgLogoPartialRight;
