import BackgroundBase from "../../components/BackgroundBase";

import MainImage from "./contactus/Image 80-fs8.png";
import MainOutlineRailingLeft from "./contactus/Image 81-fs8.png";
import MainOutlineRailingRight from "./contactus/Image 82-fs8.png";

import {TimelineLite} from 'gsap/all';
import {DESKTOP_BREAKPOINT, step1Duration} from "../../constants";
import StaticBg from "./BG-5-fs8.png";

export default class BackgroundContactUs extends BackgroundBase {
    introMobile() {
        new TimelineLite().fromTo(["#background-contact-us .main-image"], {
            width: "475px",
            y: "-46px",
            x: "30px"
        }, {
            duration: step1Duration,
            width: "506px",
            y: "0px",
            x: "0px"
        })

        new TimelineLite().fromTo(["#background-contact-us .outline-railing-left"], {
            width: "296px",
            x: "30px",
            y: "-25px"
        }, {
            duration: step1Duration,
            width: "315px",
            x: "0px",
            y: "0px"
        })

        new TimelineLite().fromTo(["#background-contact-us .outline-railing-right"], {
            width: "118px",
            x: "5px",
            y: "-25px"
        }, {
            duration: step1Duration,
            width: "126px",
            x: "0px",
            y: "0px"
        })
    }

    introDesktop() {
        const [bgWidth, bgHeight] = this.desktopBGSize()
        new TimelineLite().fromTo(['#background-contact-us .static-background'], {
            height: (bgHeight) + "px",
            y: "-100px",
        }, {
            height: (bgHeight + 100) + "px",

            y: "-100px",
            duration: step1Duration
        // })
        }).fromTo(['#background-contact-us .static-background'],{
            y: "-100px",
            // y: "0vh"
        }, {
            y: "0",
            // y: "0",
            duration: step1Duration
        })
    }

    desktopBGSize() {
        const displayHeight = this.state.height + 100
        const bgWidth = this.state.width;  // 2636;
        const bgHeight = (1690 * (this.state.width / 2636));
        if (displayHeight > bgHeight) {
            return [2636 * (displayHeight / 1690), displayHeight]
        }
        return [bgWidth, bgHeight]
    }

    render() {
        if (!this.state.width) {
            return <></>
        }
        if (this.state.width >= DESKTOP_BREAKPOINT) {
            const [bgWidth, bgHeight] = this.desktopBGSize()
            return <div id={"background-contact-us"}>
                <img src={StaticBg} alt="" className={"static-background"} style={{
                    top: "calc((100vh + 100px) - " + bgHeight + "px)",
                    right: "calc(50vw - " + (bgWidth/2) + "px)",
                    // width: bgWidth + "px",
                    height: (bgHeight - 100) + "px",
                }}/>
            </div>
        }

        return <div id="background-contact-us">
            <img  alt="" className={"main-image"} src={MainImage}
                 style={{bottom: "-92px", left: "calc(50vw - 253px)"}}/>
            <img  alt="" className={"outline-railing-left"} src={MainOutlineRailingLeft} style={{bottom: "281px", left: "calc(50vw - 266px)"}}/>

            <img  alt="" className={"outline-railing-right"} src={MainOutlineRailingRight} style={{bottom: "270px", left: "calc(50vw + 110px)"}}/>

            {/*<div className={"white-gradient"} style={{opacity: 0, left: "calc(-50vw)"}} onClick={() => this.intro()}/>*/}
            {/*<div className={"white-overlay"}  onClick={() => this.intro()}/>*/}
        </div>
    }
}